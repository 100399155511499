// CaseStatusModal.tsx
import React from 'react';
import Modal from '@/components/ui/Modal';
import StatusDropdown from '@/components/ui/DropdownWithLabel/StatusDropdown';
import { useUpdateCaseStatusMutation } from '../services/cases.mutations';
import { ClipboardList } from 'lucide-react';

interface CaseStatusModalProps {
    caseId: string;
    currentStatus: string;
    open: boolean;
    setOpen: (open: boolean) => void;
}

export const CaseStatusModal: React.FC<CaseStatusModalProps> = ({
                                                                    caseId,
                                                                    currentStatus,
                                                                    open,
                                                                    setOpen
                                                                }) => {
    const [selectedStatus, setSelectedStatus] = React.useState(currentStatus);
    const updateStatus = useUpdateCaseStatusMutation(caseId);

    const handleSubmit = async () => {
        await updateStatus.mutateAsync(selectedStatus);
        setOpen(false);
    };

    return (
        <Modal
            open={open}
            setOpen={setOpen}
            title="Ενημέρωση Κατάστασης"
            icon={<ClipboardList className="h-6 w-6 text-primary-600" />}
            primaryAction={{
                text: updateStatus.isPending ? 'Ενημέρωση...' : 'Ενημέρωση',
                onClick: handleSubmit
            }}
            secondaryAction={{
                text: 'Ακύρωση',
                onClick: () => setOpen(false)
            }}
        >
            <div className="mt-4">
                <StatusDropdown
                    value={selectedStatus}
                    onChange={setSelectedStatus}
                    required
                />
            </div>
        </Modal>
    );
};
