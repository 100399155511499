import React from 'react';
import {type ColumnDef} from '@tanstack/react-table';
import {Plus, Search} from 'lucide-react';
import ColumnTitle from '@/components/ui/DataTable/ColumnTitle';
import {TimeFormatted} from '@/components/ui/Time/TimeFormatted';
import DataTable from "@/components/ui/DataTable";
import {PageToolbar} from "@/components/layouts/PageLayout/PageToolbar";
import {PageTitle} from "@/components/layouts/PageLayout/PageTitle";
import {useNavigate} from "react-router-dom";
import {ROUTES} from "@/constants/routes.constants";
import {PriorityName} from "@/types/priority.types";
import {StatusName} from "@/types/status.types";
import StatusBadge from "@/components/ui/Badge/StatusBadge";
import PriorityBadge from "@/components/ui/Badge/PriorityBadge";
import {useGetTicketsQuery} from '@/components/features/tickets/services/tickets.queries';
import {useQueryClient} from '@tanstack/react-query';
import {TICKET_KEYS} from '@/components/features/tickets/services/tickets.queryKeys';
import type {Ticket, TicketFilters} from '@/types/ticket.types';
import useDebounce from "@/hooks/useDebounce";
import {ButtonVariant} from "@/components/ui/Button/ButtonSize";
import InputWithLabel from "@/components/ui/InputWithLabel";
import StatusDropdown from "@/components/ui/DropdownWithLabel/StatusDropdown";
import PriorityDropdown from "@/components/ui/DropdownWithLabel/PriorityDropdown";
import CategoryDropdown from "@/components/ui/DropdownWithLabel/CategoryDropdown";
import Button from "@/components/ui/Button";
import useGlobalStore from "@/stores/useGlobalStore";
import ProductDropdown from "@/components/ui/DropdownWithLabel/ProductDropdown";
import {isClient, isMindsysExternalPartner} from "@/utils/auth.utils";
import useUserStore from "@/stores/useUserStore";
import MonthDropdown from "@/components/ui/DropdownWithLabel/MonthDropdown";
import YearDropdown from "@/components/ui/DropdownWithLabel/YearDropdown";
import CompanyDropdown from "@/components/ui/DropdownWithLabel/CompanyDropdown";

interface TicketRow {
    id: string;
    number: string;
    title: string;
    status: StatusName;
    priority: PriorityName;
    company: string;
    department: string;
    assignee?: string;
    createdAt: string;
    updatedAt: string;
    category: string;
    caseNumbers: string;
}

const mapTicketToRow = (ticket: Ticket): TicketRow => ({
    id: ticket.ticket_id,
    number: ticket.ticket_number.toString(),
    title: ticket.title,
    status: ticket.status.status_name as StatusName,
    priority: ticket.priority.priority_name as PriorityName,
    company: ticket.company.company_name,
    department: ticket.department.department_name,
    assignee: ticket.assignee ? `${ticket.assignee.first_name} ${ticket.assignee.last_name}` : undefined,
    category: ticket.category.category_name,
    caseNumbers: ticket.case_numbers,
    createdAt: ticket.created_at,
    updatedAt: ticket.updated_at
});

const columns: ColumnDef<TicketRow, string>[] = [
    {
        id: 'number',
        header: ({column}) => (
            <ColumnTitle title="Αριθμός" isSortable column={column}/>
        ),
        accessorFn: (row) => row.number,
        cell: ({row}) => (
            <div className="flex flex-col">
                <span className="font-medium text-gray-900">#{row.original.number}</span>
                <span className="text-sm text-gray-500 truncate max-w-md">
                    {row.original.title}
                </span>
            </div>
        ),
    },
    {
        id: 'caseNumbers',
        header: ({column}) => (
            <ColumnTitle title="Υποθέσεις" isSortable column={column}/>
        ),
        accessorFn: (row) => row.caseNumbers,
        cell: ({row}) => (
            <div className="flex flex-col">
                <span className="font-medium text-gray-900">{row.original.caseNumbers}</span>
            </div>
        ),
    },
    {
        id: 'status',
        header: ({column}) => (
            <ColumnTitle title="Κατάσταση" isSortable column={column}/>
        ),
        accessorFn: (row) => row.status,
        cell: ({row}) => (
            <StatusBadge status={row.original.status}/>
        ),
    },
    {
        id: 'priority',
        header: ({column}) => (
            <ColumnTitle title="Προτεραιότητα" isSortable column={column}/>
        ),
        accessorFn: (row) => row.priority,
        cell: ({row}) => (
            <PriorityBadge priority={row.original.priority}/>
        ),
    },
    {
        id: 'company',
        header: ({column}) => (
            <ColumnTitle title="Εταιρεία" isSortable column={column}/>
        ),
        accessorKey: 'company',
    },
    {
        id: 'department',
        header: ({column}) => (
            <ColumnTitle title="Τμήμα" isSortable column={column}/>
        ),
        accessorKey: 'department',
    },
    {
        id: 'assignee',
        header: ({column}) => (
            <ColumnTitle title="Ανατέθηκε" isSortable column={column}/>
        ),
        accessorKey: 'assignee',
        cell: ({row}) => (
            row.original.assignee ||
            <span className="text-gray-400 italic">Μη ανατεθειμένο</span>
        ),
    },
    {
        id: 'category',
        header: ({column}) => (
            <ColumnTitle title="Κατηγορία" isSortable column={column}/>
        ),
        accessorKey: 'category',
    },
    {
        id: 'updatedAt',
        header: ({column}) => (
            <ColumnTitle title="Τελευταία Ενημέρωση" isSortable column={column}/>
        ),
        accessorFn: (row) => new Date(row.updatedAt).getTime().toString(),
        cell: ({row}) => (
            <TimeFormatted date={row.original.updatedAt}/>
        ),
    },
];


const FiltersPanel: React.FC<{
    filters: TicketFilters;
    onFiltersChange: (filters: TicketFilters) => void;
    onSearchChange: (search: string) => void;
    searchTerm: string;
    onClear: () => void;
}> = ({filters, onFiltersChange, onSearchChange, searchTerm, onClear}) => {
    const {user} = useUserStore();
    const roles = user?.roles || [];

    const handleFilterChange = (key: keyof TicketFilters, value: string) => {
        onFiltersChange({
            ...filters,
            [key]: value || undefined,
            page_number: 1
        });
    };

    return (
        <div className="bg-white p-4 rounded-lg shadow space-y-4">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                {(!isClient(roles) || !isMindsysExternalPartner(roles)) && (
                    <>
                        <CompanyDropdown
                            value={filters.company_id ?? ''}
                            onChange={(value) => handleFilterChange('company_id', value)}
                            placeholder="Όλες οι εταιρίες"
                        />
                        <ProductDropdown
                            value={filters.product_id ?? ''}
                            onChange={(value) => handleFilterChange('product_id', value)}
                            placeholder="Όλα τα προϊόντα"
                        />
                        <MonthDropdown
                            value={filters.month ?? -1}
                            onChange={(value) => handleFilterChange('month', value.toString())}
                            placeholder="Όλοι οι Μήνες"
                        />
                        <YearDropdown
                            value={filters.year ?? -1}
                            onChange={(value) => handleFilterChange('year', value.toString())}
                            placeholder="Όλα τα Έτη"
                        />
                    </>
                )}

                <StatusDropdown
                    value={filters.status_id ?? ''}
                    onChange={(value) => handleFilterChange('status_id', value)}
                    placeholder="Όλες οι καταστάσεις"
                />
                <PriorityDropdown
                    value={filters.priority_id ?? ''}
                    onChange={(value) => handleFilterChange('priority_id', value)}
                    placeholder="Όλες οι προτεραιότητες"
                />
                <CategoryDropdown
                    value={filters.category_id ?? ''}
                    onChange={(value) => handleFilterChange('category_id', value)}
                    placeholder="Όλες οι κατηγορίες"
                />
            </div>
            <div>
                <InputWithLabel
                    label="Αναζήτηση"
                    placeholder="Αναζήτηση με αριθμό αιτήματος ή τίτλο/περιγραφή..."
                    value={searchTerm}
                    onChange={(e) => onSearchChange(e.target.value)}
                    icon={Search}
                />
            </div>
            <div className="flex justify-end space-x-2">
                <Button
                    variant={ButtonVariant.OUTLINE}
                    onClick={onClear}
                >
                    Καθαρισμός
                </Button>
            </div>
        </div>
    );
};

const TicketsList = () => {
    const [isFiltersOpen, setIsFiltersOpen] = React.useState(false);
    const { tableStates, setTableState, clearTableState } = useGlobalStore();
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const {user} = useUserStore();
    const roles = user?.roles || [];

    // Get stored state for this specific table
    const tableState = tableStates['tickets-table'];

    const [filters, setFilters] = React.useState<TicketFilters>(() => ({
        page_number: (tableState?.pagination?.pageIndex ?? 0) + 1,
        page_size: tableState?.pagination?.pageSize ?? 10,
        ...tableState?.filters
    }));

    const [searchTerm, setSearchTerm] = React.useState('');
    const debouncedSearch = useDebounce(searchTerm, 500);

    // Fetch tickets with filters
    const {data, isLoading, isError} = useGetTicketsQuery({
        ...filters,
        search: debouncedSearch || undefined
    });

    // console.log('data ', data);

    const tickets = React.useMemo(() => {
        if (!data?.data) return [];
        return data.data.map(mapTicketToRow);
    }, [data]);

    const handleFiltersChange = (newFilters: TicketFilters) => {
        setFilters(newFilters);
    };

    const handleClearFilters = () => {
        clearTableState('tickets-table');
        setFilters({
            page_number: 1,
            page_size: filters.page_size,
            ticket_number: undefined,
            ticket_text: undefined,
            status_id: undefined,
            priority_id: undefined,
            category_id: undefined,
            company_id: undefined,
            product_id: undefined,
            month: undefined,
            year: undefined
        });
        setSearchTerm('');


        // Force clear dropdown values
        const dropdowns = document.querySelectorAll('select');
        dropdowns.forEach(dropdown => {
            dropdown.value = '';
        });
    };

    const getActiveFiltersCount = () => {
        return Object.entries(filters).filter(([key, value]) =>
            key !== 'page_number' &&
            key !== 'page_size' &&
            key !== 'ticket_number' &&
            key !== 'ticket_text' &&
            value !== undefined &&
            value !== ''
        ).length + (searchTerm ? 1 : 0);
    };

    const handleAddNew = () => {
        navigate(ROUTES.TICKETS.NEW);
    };

    const handleRefresh = async () => {
        // // Get current query key with all filters
        // const currentQueryKey = [
        //     TICKET_KEYS.base,
        //     {
        //         ...filters,
        //         ticket_number: /^\d+$/.test(searchTerm) ? parseInt(searchTerm) : undefined,
        //         ticket_text: /^\d+$/.test(searchTerm) ? undefined : searchTerm || undefined
        //     }
        // ];

        await queryClient.refetchQueries({
            queryKey: [TICKET_KEYS.base]
        });
    };

    const handleRowClick = (row: TicketRow) => {
        // Save current state
        setTableState('tickets-table', {
            filters: {
                ...filters,
                search: debouncedSearch || undefined
            },
            pagination: {
                pageIndex: filters.page_number - 1,
                pageSize: filters.page_size
            }
        });

        navigate(ROUTES.TICKETS.DETAILS(row.id));
    };

    const handlePageChange = (pageIndex: number, pageSize: number) => {
        const newFilters = {
            ...filters,
            page_number: pageIndex + 1,
            page_size: pageSize
        };
        setFilters(newFilters);

        // Update stored state
        setTableState('tickets-table', {
            filters: newFilters,
            pagination: { pageIndex, pageSize }
        });
    };

    const visibleColumns = {
        caseNumbers: !isClient(roles)
    }

    const handleSearchChange = (searchValue: string) => {
        setSearchTerm(searchValue);

        // Check if search term is a number
        const isNumber = /^\d+$/.test(searchValue);

        setFilters(prev => ({
            ...prev,
            ticket_number: isNumber ? searchValue : undefined,
            ticket_text: isNumber ? undefined : searchValue || undefined,
            page_number: 1 // Reset to first page on search
        }));
    };

    React.useEffect(() => {
        // Get the stored state for this specific table
        const tableState = useGlobalStore.getState().tableStates['tickets-table'];

        if (tableState) {
            // Set filters from stored state
            setFilters({
                ...tableState.filters,
                page_number: (tableState.pagination?.pageIndex ?? 0) + 1,
                page_size: tableState.pagination?.pageSize ?? 10
            });

            // Set search term if it exists in stored filters
            if (tableState.filters) {
                setSearchTerm(
                    tableState.filters.ticket_number ||
                    tableState.filters.ticket_text ||
                    ''
                );
            }

            // Clear the stored state after applying it
            useGlobalStore.getState().clearTableState('tickets-table');
        }
    }, []);

    if (isError) {
        return (
            <div className="h-full w-full flex items-center justify-center">
                <p className="text-error-500">Σφάλμα φόρτωσης δεδομένων</p>
            </div>
        );
    }

    return (
        <div className="h-full w-full mx-auto p-4 sm:p-6 lg:p-8">
            <PageTitle
                title="Αιτήματα"
                description="Διαχείριση και παρακολούθηση όλων των αιτημάτων υποστήριξης"
            />
            <PageToolbar
                primaryAction={{
                    label: 'Νέο Αίτημα',
                    icon: Plus,
                    onClick: handleAddNew
                }}
                onRefreshClick={handleRefresh}
                onFilterClick={() => setIsFiltersOpen(!isFiltersOpen)}
                activeFiltersCount={getActiveFiltersCount()}
            />
            {isFiltersOpen && (
                <div className="mb-4">
                    <FiltersPanel
                        filters={filters}
                        onFiltersChange={handleFiltersChange}
                        searchTerm={searchTerm}
                        onSearchChange={handleSearchChange}
                        onClear={handleClearFilters}
                    />
                </div>
            )}
            <DataTable
                columns={columns}
                columnVisibility={visibleColumns}
                data={tickets}
                enableGlobalFilter={false}
                enableColumnFilters={false}
                noDataMessage={
                    Object.keys(filters).length > 2 || searchTerm
                        ? "Δεν βρέθηκαν αποτελέσματα με τα επιλεγμένα φίλτρα"
                        : "Δεν υπάρχουν αιτήματα προς εμφάνιση"
                }
                onRowClick={handleRowClick}
                isLoading={isLoading}
                serverPagination={data ? {
                    totalPages: data.total_pages,
                    totalResults: data.total_results,
                    onPageChange: handlePageChange
                } : undefined}
                initialState={{
                    pagination: {
                        pageSize: filters.page_size,
                        pageIndex: filters.page_number - 1
                    }
                }}
            />
        </div>
    );
};

export default TicketsList;
