import React, { Fragment, useState } from 'react';
import {Dialog, Input, Transition} from '@headlessui/react';
import { Search, X, Check } from 'lucide-react';
import { useDuplicateTicketMutation } from '../services/cases.mutations';

import Button from "@/components/ui/Button";
import { useGetUsersQuery } from "@/components/features/users/services/users.queries";
import useUserStore from "@/stores/useUserStore";
import {Avatar} from "@/components/ui/Avatar/Avatar";
import {RoleBadge} from "@/components/ui/Badge";
import {ButtonVariant} from "@/components/ui/Button/ButtonSize";

interface TicketDuplicateDialogProps {
    caseId: string;
    ticketId: string;
    open: boolean;
    onClose: () => void;
}

export const TicketDuplicateDialog: React.FC<TicketDuplicateDialogProps> = ({
                                                                                caseId,
                                                                                ticketId,
                                                                                open,
                                                                                onClose
                                                                            }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedUsers, setSelectedUsers] = useState<string[]>([]);

    const user = useUserStore(state => state.user);
    const companyId = user?.company_id;

    const { data: usersData } = useGetUsersQuery({
        company_id: companyId,
        page_number: 1,
        page_size: 400
    });

    const duplicateTicketMutation = useDuplicateTicketMutation(caseId);

    const filteredUsers = usersData?.data.filter(user => {
        const fullName = `${user.first_name} ${user.last_name}`.toLowerCase();
        return fullName.includes(searchTerm.toLowerCase());
    }) || [];

    const toggleUserSelection = (userId: string) => {
        setSelectedUsers(prev =>
            prev.includes(userId)
                ? prev.filter(id => id !== userId)
                : [...prev, userId]
        );
    };

    const handleClose = () => {
        setSelectedUsers([]);
        setSearchTerm('');
        onClose();
    };

    const handleDuplicate = async () => {
        if (selectedUsers.length === 0) return;

        await duplicateTicketMutation.mutateAsync({
            ticket_id: ticketId,
            assignees: selectedUsers
        }, {
            onSuccess: () => {
                handleClose();
            }
        });
    };

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-50" onClose={handleClose}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                                    <button
                                        type="button"
                                        className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                                        onClick={handleClose}
                                    >
                                        <span className="sr-only">Close</span>
                                        <X className="h-6 w-6" aria-hidden="true" />
                                    </button>
                                </div>

                                <div className="mt-3">
                                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                                        Δημιουργία Αντιγράφων Ticket
                                    </Dialog.Title>
                                    <div className="mt-4">
                                        <div className="relative">
                                            <Input
                                                placeholder="Αναζήτηση χρηστών..."
                                                value={searchTerm}
                                                onChange={(e) => setSearchTerm(e.target.value)}
                                                className="pr-10"
                                            />
                                            <Search className="absolute right-3 top-2.5 h-4 w-4 text-gray-400" />
                                        </div>
                                        <div className="mt-2 text-sm text-gray-500">
                                            Επιλεγμένοι χρήστες: {selectedUsers.length}
                                        </div>
                                        <div className="mt-4 max-h-[300px] overflow-y-auto space-y-2">
                                            {filteredUsers.map(user => (
                                                <div
                                                    key={user.user_id}
                                                    className={`flex items-center justify-between p-2 hover:bg-gray-100 rounded-md cursor-pointer ${
                                                        selectedUsers.includes(user.user_id) ? 'bg-gray-100' : ''
                                                    }`}
                                                    onClick={() => toggleUserSelection(user.user_id)}
                                                >
                                                    <div className="flex items-center space-x-3">
                                                        <Avatar
                                                            firstName={user.first_name}
                                                            lastName={user.last_name}
                                                            roleName={user.role.role_name}
                                                        />
                                                        <div>
                                                            <p className="font-medium">
                                                                {`${user.first_name} ${user.last_name}`}
                                                            </p>
                                                            <RoleBadge role={user.role} />
                                                        </div>
                                                    </div>
                                                    {selectedUsers.includes(user.user_id) && (
                                                        <Check className="h-5 w-5 text-green-500" />
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>

                                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                    <Button
                                        variant={ButtonVariant.SECONDARY}
                                        onClick={handleClose}
                                    >
                                        Ακύρωση
                                    </Button>
                                    <Button
                                        onClick={handleDuplicate}
                                        disabled={selectedUsers.length === 0 || duplicateTicketMutation.isPending}
                                    >
                                        {duplicateTicketMutation.isPending ? "Δημιουργία..." : "Δημιουργία Αντιγράφων"}
                                    </Button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
};
