// components/ui/DropdownWithLabel/MonthDropdown.tsx
import React from 'react';
import DropdownWithLabel from '@/components/ui/DropdownWithLabel';

interface MonthDropdownProps {
    label?: string;
    value?: number;
    onChange?: (value: number) => void;
    error?: string;
    required?: boolean;
    disabled?: boolean;
    placeholder?: string;
    helperText?: string;
}

const months = [
    "Ιανουάριος",
    "Φεβρουάριος",
    "Μάρτιος",
    "Απρίλιος",
    "Μάιος",
    "Ιούνιος",
    "Ιούλιος",
    "Αύγουστος",
    "Σεπτέμβριος",
    "Οκτώβριος",
    "Νοέμβριος",
    "Δεκέμβριος"
];

const MonthDropdown = React.forwardRef<HTMLSelectElement, MonthDropdownProps>(
    ({
         label = 'Μήνας',
         value,
         onChange,
         error,
         required = false,
         disabled = false,
         placeholder = 'Επιλέξτε Μήνα',
         helperText,
         ...props
     }, ref) => {
        const [currentValue, setCurrentValue] = React.useState<number>(-1);

        React.useEffect(() => {
            if (value) {
                setCurrentValue(value);
            } else {
                setCurrentValue(-1);
            }
        }, [value]);

        const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
            const strValue = parseInt(event.target.value);
            const newValue = !isNaN(strValue) ? strValue : -1;
            setCurrentValue(newValue);
            onChange?.(newValue);
        };

        const monthOptions = React.useMemo(() => {
            return [
                { value: '', label: placeholder },
                ...months.map((month, index) => ({
                    value: index,
                    label: month,
                }))
            ];
        }, [placeholder]);

        return (
            <DropdownWithLabel
                ref={ref}
                label={label}
                options={monthOptions}
                value={currentValue}
                onChange={handleChange}
                error={error}
                disabled={disabled}
                required={required}
                helperText={helperText}
                {...props}
            />
        );
    }
);

MonthDropdown.displayName = 'MonthDropdown';

export default MonthDropdown;
