// components/ui/DropdownWithLabel/ProductDropdown.tsx
import React from 'react';
import DropdownWithLabel from '@/components/ui/DropdownWithLabel';
import type {Product} from '@/types/product.types';
import {useGetProductsQuery} from "@/components/features/products/services/products.queries";

interface ProductDropdownProps {
    label?: string;
    value?: string;
    onChange?: (value: string) => void;
    error?: string;
    required?: boolean;
    disabled?: boolean;
    placeholder?: string;
    helperText?: string;
}

const ProductDropdown = React.forwardRef<HTMLSelectElement, ProductDropdownProps>(
    ({
         label = 'Προϊόντα',
         value,
         onChange,
         error,
         required = false,
         disabled = false,
         placeholder = 'Επιλέξτε προϊόν',
         helperText,
         ...props
     }, ref) => {
        const { data: products, isLoading, isError } = useGetProductsQuery();
        const [currentValue, setCurrentValue] = React.useState<string>('');

        React.useEffect(() => {
            if (value) {
                setCurrentValue(value);
            } else {
                setCurrentValue('');
            }
        }, [value, products]);

        const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
            const newValue = event.target.value;
            setCurrentValue(newValue);
            onChange?.(newValue);
        };

        const productOptions = React.useMemo(() => {
            if (!products) return [];

            return [
                { value: '', label: placeholder },
                ...products.map((type: Product) => ({
                    value: type.product_id,
                    label: type.product_name,
                }))
            ];
        }, [products, placeholder]);

        if (isError) {
            return (
                <DropdownWithLabel
                    ref={ref}
                    label={label}
                    options={[]}
                    error="Σφάλμα φόρτωσης προϊόντων"
                    disabled
                    required={required}
                    {...props}
                />
            );
        }

        return (
            <DropdownWithLabel
                ref={ref}
                label={label}
                options={productOptions}
                value={currentValue}
                onChange={handleChange}
                error={error}
                disabled={disabled || isLoading}
                required={required}
                helperText={helperText}
                {...props}
            />
        );
    }
);

ProductDropdown.displayName = 'ProductDropdown';

export default ProductDropdown;
