// src/features/notifications/services/notifications.queries.ts

import { useQuery } from '@tanstack/react-query';
import { notificationsApi } from './notifications.api';
import { NOTIFICATION_KEYS } from './notifications.queryKeys';
import type { NotificationFilters } from '@/types/notification.types';

export function useGetUserNotificationsQuery(filters?: NotificationFilters) {
    return useQuery({
        queryKey: NOTIFICATION_KEYS.list(filters),
        queryFn: () => notificationsApi.getUserNotifications(filters),
        staleTime: 1000 * 60, // 1 minute - shorter than tickets since notifications are more time-sensitive
        refetchOnWindowFocus: true
    });
}
