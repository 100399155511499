// RoleBadge.tsx
import {getRoleColorClasses} from "@/constants/colors.constants";
import {cn} from "@/lib/utils";

interface RoleBadgeProps {
    role: {
        role_name: string;
    };
    className?: string;
}

export function RoleBadge({ role, className }: RoleBadgeProps) {
    const roleType = role.role_name === 'Administrator' ? 'administrator'
        : role.role_name === 'Ticket Admin.' ? 'helpdesk'
            : role.role_name === 'Manager' ? 'manager'
                : role.role_name === 'Agent' ? 'agent'
                    : role.role_name === 'Διαχειριστής' ? 'client_administrator'
                        : role.role_name === 'Προϊστάμενος' ? 'client_manager'
                            : role.role_name === 'Εκπρόσωπος' ? 'client_employee'
                                : 'agent';

    const colors = getRoleColorClasses(roleType);

    return (
        <span className={cn(
            'inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium border',
            colors?.bg,
            colors?.text,
            colors?.border,
            className
        )}>
            {role.role_name}
        </span>
    );
}
