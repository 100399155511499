// cases.mutations.ts
import { toast } from 'react-toastify';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { casesApi } from "./cases.api";
import { CASE_KEYS } from "./cases.queryKeys";
import type { CreateCaseRequest, UpdateCaseRequest } from "@/types/case.types";
import {useGetCaseQuery} from "@/components/features/cases/services/cases.queries";

export function useCreateCaseMutation() {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (data: CreateCaseRequest) => casesApi.create(data),
        onSuccess: async (response) => {
            if (!response.action_status) {
                toast.error(response.action_message || 'Σφάλμα κατά τη δημιουργία της υπόθεσης');
                return;
            }

            // Invalidate all case lists and the base query
            await queryClient.invalidateQueries({
                queryKey: CASE_KEYS.lists(),
                refetchType: 'all'
            });

            toast.success('Η υπόθεση δημιουργήθηκε επιτυχώς');
            return response;
        },
        onError: (error) => {
            console.error('Create case error:', error);
            toast.error('Σφάλμα κατά τη δημιουργία της υπόθεσης');
        }
    });
}

export function useUpdateCaseMutation(caseId: string) {
    const queryClient = useQueryClient();
    const { data: currentCase } = useGetCaseQuery(caseId);

    return useMutation({
        mutationFn: (data: Partial<UpdateCaseRequest>) => {
            if (!currentCase) throw new Error('Case not found');

            return casesApi.update(caseId, {
                title: data.title ?? currentCase.title,
                description: data.description ?? currentCase.description,
                category_id: data.category_id ?? currentCase.category.category_id,
                priority_id: data.priority_id ?? currentCase.priority.priority_id,
                status_id: data.status_id ?? currentCase.status.status_id,
                assignee_id: data.assignee_id ?? currentCase.assignee?.user_id,
                tickets: data.tickets ?? currentCase.tickets.map(t => t.ticket.ticket_id)
            });
        },
        onSuccess: async (response) => {
            if (!response.action_status) {
                toast.error(response.action_message || 'Σφάλμα κατά την ενημέρωση της υπόθεσης');
                return;
            }

            // Invalidate both the specific case and all lists
            await Promise.all([
                queryClient.invalidateQueries({
                    queryKey: CASE_KEYS.detail(caseId),
                    refetchType: 'all'
                }),
                queryClient.invalidateQueries({
                    queryKey: CASE_KEYS.lists(),
                    refetchType: 'all'
                })
            ]);

            toast.success('Η υπόθεση ενημερώθηκε επιτυχώς');
        },
        onError: (error) => {
            console.error('Update case error:', error);
            toast.error('Σφάλμα κατά την ενημέρωση της υπόθεσης');
        }
    });
}

export function useUpdateCaseStatusMutation(caseId: string) {
    const queryClient = useQueryClient();
    const { data: currentCase } = useGetCaseQuery(caseId);

    return useMutation({
        mutationFn: (statusId: string) => {
            if (!currentCase) throw new Error('Case not found');

            return casesApi.update(caseId, {
                title: currentCase.title,
                description: currentCase.description,
                category_id: currentCase.category.category_id,
                priority_id: currentCase.priority.priority_id,
                status_id: statusId,
                assignee_id: currentCase.assignee?.user_id,
                tickets: currentCase.tickets.map(t => t.ticket.ticket_id)
            });
        },
        onSuccess: async (response) => {
            if (!response.action_status) {
                toast.error(response.action_message || 'Σφάλμα κατά την ενημέρωση της κατάστασης');
                return;
            }
            await queryClient.invalidateQueries({ queryKey: CASE_KEYS.base });
            toast.success('Η κατάσταση της υπόθεσης ενημερώθηκε επιτυχώς');
        },
        onError: (error) => {
            console.error('Update status error:', error);
            toast.error('Σφάλμα κατά την ενημέρωση της κατάστασης');
        }
    });
}

export function useUpdateCaseAssigneeMutation(caseId: string) {
    const queryClient = useQueryClient();
    const { data: currentCase } = useGetCaseQuery(caseId);

    return useMutation({
        mutationFn: (userId: string) => {
            if (!currentCase) throw new Error('Case not found');

            return casesApi.update(caseId, {
                title: currentCase.title,
                description: currentCase.description,
                category_id: currentCase.category.category_id,
                priority_id: currentCase.priority.priority_id,
                status_id: currentCase.status.status_id,
                assignee_id: userId,
                tickets: currentCase.tickets.map(t => t.ticket.ticket_id)
            });
        },
        onSuccess: async (response) => {
            if (!response.action_status) {
                toast.error(response.action_message || 'Σφάλμα κατά την ανάθεση της υπόθεσης');
                return;
            }
            await queryClient.invalidateQueries({ queryKey: CASE_KEYS.base });
            toast.success('Η ανάθεση της υπόθεσης ενημερώθηκε επιτυχώς');
        },
        onError: (error) => {
            console.error('Update assignee error:', error);
            toast.error('Σφάλμα κατά την ανάθεση');
        }
    });
}

export function useDuplicateTicketMutation(caseId: string) {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (data: { ticket_id: string; assignees: string[] }) =>
            casesApi.duplicateTicket(caseId, data),
        onSuccess: async (response) => {
            if (!response.action_status) {
                toast.error(response.action_message || 'Σφάλμα κατά τη δημιουργία αντιγράφων');
                return;
            }

            // Invalidate both the specific case and related queries
            await Promise.all([
                queryClient.invalidateQueries({
                    queryKey: CASE_KEYS.detail(caseId),
                }),
                queryClient.invalidateQueries({
                    queryKey: CASE_KEYS.lists(),
                })
            ]);

            toast.success('Τα αντίγραφα δημιουργήθηκαν επιτυχώς');
            return response;
        },
        onError: (error) => {
            console.error('Duplicate ticket error:', error);
            toast.error('Σφάλμα κατά τη δημιουργία αντιγράφων');
        }
    });
}
