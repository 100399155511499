// src/pages/AdministratorDashboard.tsx
import React from 'react';
import {Activity, AlertTriangle, BarChart3, CheckCircle2, ClipboardList, Clock, Timer, UserCog} from 'lucide-react';
import {Card, CardContent} from "@/components/ui/Card";
import {StatCard} from "@/components/ui/StatsCard/StatsCard";
import {TicketListCard} from "@/components/ui/TicketListCard/TicketListCard";
import {
    useAboutToBreachTickets,
    useActiveTickets,
    useCriticalTickets,
    useUnassignedTickets
} from "@/hooks/specializedTickets.hooks";
import {useGetStatisticsQuery} from "@/components/features/statistics/services/statistics.queries";

export default function AdministratorDashboard() {
    const unassignedTickets = useUnassignedTickets();
    const criticalTickets = useCriticalTickets();
    const activeTickets = useActiveTickets();
    const aboutToBreachTickets = useAboutToBreachTickets();
    const {data: statistics = []} = useGetStatisticsQuery();

    const getStatisticValueByKey = (key: string) => {
        const item = statistics.find((entry) => entry.key === key);
        return item ? item.value : "N/A";
    };

    return (
        <div className="h-full w-full bg-gray-100">
            <div className="mx-auto p-4 sm:p-6 lg:p-8">
                <div className="mb-8">
                    <h1 className="text-2xl font-bold text-gray-900">Πίνακας Ελέγχου</h1>
                    <p className="mt-1 text-sm text-gray-500">
                        Επισκόπηση συστήματος και διαχείριση αιτημάτων
                    </p>
                </div>

                {/* Primary Stats Grid */}
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4 mb-8">
                    {/* Open Tickets Card */}
                    <StatCard
                        title="Μη Ανατεθειμένα"
                        value={getStatisticValueByKey("unassigned_tickets")}
                        // suffix=" αιτήματα"
                        icon={ClipboardList}
                        className="bg-blue-50 border-blue-200"
                        iconClassName="bg-blue-100 text-blue-700"
                    />

                    {/* Active Tickets */}
                    <StatCard
                        title="Ενεργά Αιτήματα"
                        value={getStatisticValueByKey("active_tickets")}
                        icon={Activity}
                        className="bg-green-50 border-green-200"
                        iconClassName="bg-green-100 text-green-700"
                        trendIsPositive={false}
                    />

                    {/* Response Time */}
                    <Card className="bg-purple-50 border-purple-200">
                        <CardContent className="p-6">
                            <div className="flex items-center justify-between">
                                <div>
                                    <p className="text-sm font-medium text-purple-700">Μέσος Χρόνος Απόκρισης</p>
                                    <div className="flex items-baseline">
                                        <p className="text-2xl font-semibold text-purple-900">{getStatisticValueByKey("average_response_time")} ώρες</p>
                                    </div>
                                </div>
                                <div className="p-3 bg-purple-100 rounded-lg">
                                    <Timer className="h-6 w-6 text-purple-700"/>
                                </div>
                            </div>
                        </CardContent>
                    </Card>

                    {/* SLA Breaches - Warning Card */}
                    <Card className="bg-error-50 border-error-200">
                        <CardContent className="p-6">
                            <div className="flex items-center justify-between">
                                <div>
                                    <p className="text-sm font-medium text-error-700">Παραβιάσεις SLA</p>
                                    <div className="flex items-baseline">
                                        <p className="text-2xl font-semibold text-error-900">{getStatisticValueByKey("sla_breaches")}</p>
                                    </div>
                                </div>
                                <div className="p-3 bg-error-100 rounded-lg">
                                    <AlertTriangle className="h-6 w-6 text-error-700"/>
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                </div>

                {/* Secondary Stats Grid */}
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4 mb-8">
                    {/* Resolution Time */}
                    <Card>
                        <CardContent className="p-6">
                            <div className="flex items-center justify-between">
                                <div>
                                    <p className="text-sm font-medium text-gray-600">Μέσος Χρόνος Επίλυσης</p>
                                    <div className="flex items-baseline">
                                        <p className="text-2xl font-semibold text-gray-900">{getStatisticValueByKey("average_resolution_time")} ώρες</p>
                                    </div>
                                </div>
                                <div className="p-3 bg-gray-100 rounded-lg">
                                    <Clock className="h-6 w-6 text-gray-600"/>
                                </div>
                            </div>
                        </CardContent>
                    </Card>

                    {/* Available Agents */}
                    <Card>
                        <CardContent className="p-6">
                            <div className="flex items-center justify-between">
                                <div>
                                    <p className="text-sm font-medium text-gray-600">Συνδεδεμένοι Χρήστες</p>
                                    <div className="flex items-baseline">
                                        <p className="text-2xl font-semibold text-gray-900">{getStatisticValueByKey("online_users")}</p>
                                    </div>
                                </div>
                                <div className="p-3 bg-gray-100 rounded-lg">
                                    <UserCog className="h-6 w-6 text-gray-600"/>
                                </div>
                            </div>
                        </CardContent>
                    </Card>

                    {/* Customer Satisfaction */}
                    <Card>
                        <CardContent className="p-6">
                            <div className="flex items-center justify-between">
                                <div>
                                    <p className="text-sm font-medium text-gray-600">Ικανοποίηση Πελατών</p>
                                    <div className="flex items-baseline">
                                        <p className="text-2xl font-semibold text-gray-900">{getStatisticValueByKey("customer_satisfaction")}</p>
                                    </div>
                                </div>
                                <div className="p-3 bg-gray-100 rounded-lg">
                                    <CheckCircle2 className="h-6 w-6 text-gray-600"/>
                                </div>
                            </div>
                        </CardContent>
                    </Card>

                    {/* Daily TicketTypes Average */}
                    <Card>
                        <CardContent className="p-6">
                            <div className="flex items-center justify-between">
                                <div>
                                    <p className="text-sm font-medium text-gray-600">Μ.Ο. Αιτημάτων/Ημέρα</p>
                                    <div className="flex items-baseline">
                                        <p className="text-2xl font-semibold text-gray-900">{getStatisticValueByKey("average_tickets_per_day")}</p>
                                    </div>
                                </div>
                                <div className="p-3 bg-gray-100 rounded-lg">
                                    <BarChart3 className="h-6 w-6 text-gray-600"/>
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                </div>

                {/* Main Content Grid */}
                <div className="grid grid-cols-1 gap-8 lg:grid-cols-2">
                    <TicketListCard
                        title="Μη Ανατεθειμένα Αιτήματα"
                        tickets={unassignedTickets.data?.data ?? []}
                        badgeCount={unassignedTickets.data?.total_results ?? 0}
                        badgeText="σε αναμονή"
                        badgeVariant="outline"
                        actionButtonText="Προβολή"
                        onTicketAction={unassignedTickets.handleTicketClick}
                        onViewAll={unassignedTickets.handleViewAll}
                    />

                    <TicketListCard
                        title="Κρίσιμα Αιτήματα"
                        tickets={criticalTickets.data?.data ?? []}
                        badgeCount={criticalTickets.data?.total_results ?? 0}
                        badgeText="συνολικά"
                        badgeVariant="error"
                        showSLA={true}
                        actionButtonText="Προβολή"
                        onTicketAction={criticalTickets.handleTicketClick}
                        onViewAll={criticalTickets.handleViewAll}
                    />

                    <TicketListCard
                        title="Ενεργά Αιτήματα"
                        tickets={activeTickets.data?.data ?? []}
                        badgeCount={activeTickets.data?.total_results ?? 0}
                        badgeText="συνολικά"
                        badgeVariant="outline"
                        actionButtonText="Προβολή"
                        onTicketAction={activeTickets.handleTicketClick}
                        onViewAll={activeTickets.handleViewAll}
                    />

                    <TicketListCard
                        title="Αιτήματα Προς Παραβίαση"
                        tickets={aboutToBreachTickets.data?.data ?? []}
                        badgeCount={aboutToBreachTickets.data?.total_results ?? 0}
                        badgeText="συνολικά"
                        badgeVariant="warning"
                        showSLA={true}
                        actionButtonText="Προβολή"
                        onTicketAction={aboutToBreachTickets.handleTicketClick}
                        onViewAll={aboutToBreachTickets.handleViewAll}
                    />
                </div>
            </div>
        </div>
    )
}



