// components/ui/SearchDropdown.tsx
import React from 'react';
import { Search } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@/constants/routes.constants';
import { useGetTicketsQuery } from '@/components/features/tickets/services/tickets.queries';
import useDebounce from '@/hooks/useDebounce';
import StatusBadge from '@/components/ui/Badge/StatusBadge';
import useOnClickOutside from '@/hooks/useOnClickOutside';
import useGlobalStore from "@/stores/useGlobalStore";

export const SearchDropdown = () => {
    const navigate = useNavigate();
    const [search, setSearch] = React.useState('');
    const [isOpen, setIsOpen] = React.useState(false);
    const debouncedSearch = useDebounce(search, 500);
    const dropdownRef = React.useRef<HTMLDivElement>(null);

    useOnClickOutside(dropdownRef, () => setIsOpen(false));

    const { data: searchResults, isLoading } = useGetTicketsQuery(
        debouncedSearch ? {
            page_number: 1,
            page_size: 5,
            ticket_number: /^\d+$/.test(debouncedSearch) ? debouncedSearch : undefined,
            ticket_text: /^\d+$/.test(debouncedSearch) ? undefined : debouncedSearch || undefined,
        } : {
            page_number: 1,
            page_size: 5
        }
    );

    const handleSelectTicket = (ticketId: string) => {
        setSearch('');
        setIsOpen(false);
        navigate(ROUTES.TICKETS.DETAILS(ticketId));
    };

    const handleViewAll = () => {
        if (debouncedSearch) {
            const isNumber = /^\d+$/.test(debouncedSearch);
            const filters = {
                page_number: 1,
                page_size: 10,
                ticket_number: isNumber ? debouncedSearch : undefined,
                ticket_text: isNumber ? undefined : debouncedSearch
            };

            useGlobalStore.getState().setTicketFilters(filters);
            navigate(ROUTES.TICKETS.LIST);
        }
        setIsOpen(false);
        setSearch('');
    };

    return (
        <div className="relative w-full max-w-md" ref={dropdownRef}>
            <div className="relative">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <Search className="h-4 w-4 text-gray-400" />
                </div>
                <input
                    type="text"
                    value={search}
                    onChange={(e) => {
                        setSearch(e.target.value);
                        setIsOpen(true);
                    }}
                    onFocus={() => setIsOpen(true)}
                    placeholder="Αναζήτηση με αριθμό αιτήματος ή τίτλο/περιγραφή..."
                    className="block w-full rounded-lg border-0 py-1.5 pl-10 pr-4 text-gray-900 placeholder:text-gray-400 focus:ring-2 focus:ring-primary-500 sm:text-sm sm:leading-6 bg-white"
                />
            </div>

            {/* Dropdown Results */}
            {isOpen && search && debouncedSearch && (
                <div
                    className="absolute mt-1 w-full bg-white rounded-md shadow-lg py-1 z-50 border border-gray-200"
                    onMouseDown={(e) => e.preventDefault()} // Prevent blur from closing before click
                >
                    {isLoading ? (
                        <div className="px-4 py-2 text-sm text-gray-500">
                            Αναζήτηση...
                        </div>
                    ) : searchResults?.data && searchResults.data.length > 0 ? (
                        <>
                            {searchResults.data.map((ticket) => (
                                <button
                                    key={ticket.ticket_id}
                                    onClick={() => handleSelectTicket(ticket.ticket_id)}
                                    className="w-full text-left px-4 py-2 hover:bg-gray-100 focus:bg-gray-100 focus:outline-none"
                                >
                                    <div className="flex items-center justify-between">
                                        <div className="flex flex-col">
                                            <div className="flex items-center gap-2">
                                                <span className="font-medium">#{ticket.ticket_number}</span>
                                                <StatusBadge status={ticket.status.status_name} />
                                            </div>
                                            <span className="text-sm text-gray-600 truncate max-w-xs">
                                                {ticket.title}
                                            </span>
                                        </div>
                                        <span className="text-xs text-gray-500">
                                            {ticket.company.company_name}
                                        </span>
                                    </div>
                                </button>
                            ))}
                            <div className="px-4 py-2 border-t border-gray-100">
                                <button
                                    onClick={handleViewAll}
                                    className="text-sm text-primary-600 hover:text-primary-700"
                                >
                                    Προβολή όλων των αποτελεσμάτων ({searchResults.total_results})
                                </button>
                            </div>
                        </>
                    ) : (
                        <div className="px-4 py-2 text-sm text-gray-500">
                            Δεν βρέθηκαν αποτελέσματα
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};
