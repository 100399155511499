// useGlobalStore.ts
import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'
import { getItem, setItem } from '@/lib/localStorage'
import type { TicketFilters } from '@/types/ticket.types'

interface TicketPagination {
    pageIndex: number;
    pageSize: number;
}

interface TableState {
    filters: TicketFilters | null;
    pagination: TicketPagination | null;
}

interface GlobalState {
    isSidebarOpen: boolean;
    tableStates: Record<string, TableState>;  // Store states for different tables
    ticketFilters: TicketFilters | null; // Kept from original store
}

interface GlobalStore extends GlobalState {
    toggleSidebar: (b: boolean) => void;
    setTableState: (tableId: string, state: TableState) => void;
    clearTableState: (tableId: string) => void;
    setTicketFilters: (filters: TicketFilters | null) => void; // Kept from original store
}

const initialState: Pick<GlobalStore, keyof GlobalState> = {
    isSidebarOpen: getItem('isSidebarOpen') ?? false,
    tableStates: {},
    ticketFilters: null,
}

const useGlobalStore = create<GlobalStore>()(
    devtools(
        persist(
            (set) => ({
                ...initialState,
                toggleSidebar: () => {
                    set((state) => {
                        const newValue = !state.isSidebarOpen;
                        setItem('isSidebarOpen', newValue);
                        return { isSidebarOpen: newValue };
                    });
                },
                setTableState: (tableId, state) =>
                    set((prevState) => ({
                        tableStates: {
                            ...prevState.tableStates,
                            [tableId]: state
                        }
                    })),
                clearTableState: (tableId) =>
                    set((prevState) => {
                        const newStates = { ...prevState.tableStates };
                        delete newStates[tableId];
                        return { tableStates: newStates };
                    }),
                setTicketFilters: (filters) => {
                    set({ ticketFilters: filters });
                }
            }),
            {
                name: 'global-store',
                // Only persist specific fields
                partialize: (state) => ({
                    isSidebarOpen: state.isSidebarOpen,
                    tableStates: state.tableStates
                })
            }
        ),
        {
            name: "Global Store",
            enabled: import.meta.env.DEV
        }
    )
)

export default useGlobalStore
