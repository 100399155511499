import { client } from '@/lib/httpClient';
import type { Message } from '@/types/message.types';
import type { CreateMessageRequest } from '@/schemas/message.schemas';

export const messagesApi = {
    getAll: async (ticketId: string) => {
        const response = await client.get<Message[]>(
            `/api/v1/tickets/${ticketId}/messages`
        );
        return response.data;
    },

    create: async (ticketId: string, data: CreateMessageRequest) => {
        // const validated = createMessageSchema.parse(data);
        const formData = new FormData()
        formData.append('message_content', data.message_content)
        formData.append('is_internal', data.is_internal.toString())
        if (data.files) {
            for (const file of data.files) {
                formData.append(`files`, file)
            }
        }

        const response = await client.post(
            `/api/v1/tickets/${ticketId}/messages`,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        );
        // console.log('response', response)
        return response.data;
    },

    getAttachment: async (messageAttachmentId: string) => {
        const response = await client.get<Blob>(`/api/v1/tickets/messages/attachments/${messageAttachmentId}`,
            { responseType: 'blob' }
        );
        return response.data;
    }
};
