// statistics.queries.ts
import {useQuery} from '@tanstack/react-query';
import {statisticsApi} from './statistics.api';

export function useGetStatisticsQuery() {
    return useQuery({
        queryKey: ["statistics"],
        queryFn: () => statisticsApi.getAll(),
        enabled: true,
        staleTime: 1000 * 60 * 5
    });
}