// CaseAssigneeModal.tsx
import React from 'react';
import Modal from '@/components/ui/Modal';
import UserDropdown from '@/components/ui/DropdownWithLabel/UserDropdown';
import { useUpdateCaseAssigneeMutation } from '../services/cases.mutations';
import { UserCog } from 'lucide-react';
import type { User } from '@/types/user.types';
import useUserStore from "@/stores/useUserStore";

interface CaseAssigneeModalProps {
    caseId: string;
    currentAssignee: User | null;
    open: boolean;
    setOpen: (open: boolean) => void;
}

export const CaseAssigneeModal: React.FC<CaseAssigneeModalProps> = ({
                                                                        caseId,
                                                                        currentAssignee,
                                                                        open,
                                                                        setOpen
                                                                    }) => {
    const userStore = useUserStore(state => state.user);
    const company_id = userStore.company_id;
    const [selectedUser, setSelectedUser] = React.useState(currentAssignee?.user_id || '');
    const updateAssignee = useUpdateCaseAssigneeMutation(caseId);

    const handleSubmit = async () => {
        await updateAssignee.mutateAsync(selectedUser);
        setOpen(false);
    };

    return (
        <Modal
            open={open}
            setOpen={setOpen}
            title="Ανάθεση Υπόθεσης"
            icon={<UserCog className="h-6 w-6 text-primary-600" />}
            primaryAction={{
                text: updateAssignee.isPending ? 'Ανάθεση...' : 'Ανάθεση',
                onClick: handleSubmit
            }}
            secondaryAction={{
                text: 'Ακύρωση',
                onClick: () => setOpen(false)
            }}
            className="sm:max-w-xl lg:max-w-2xl"
        >
            <div className="mt-4 min-h-[400px]"> {/* Added min-height here */}
                <UserDropdown
                    label="Επιλογή Χρήστη"
                    value={selectedUser}
                    onChange={setSelectedUser}
                    company_id={company_id}
                    required
                />

                {currentAssignee && (
                    <div className="mt-4 p-4 bg-gray-50 rounded-lg">
                        <p className="text-sm text-gray-600">
                            Τρέχουσα ανάθεση: <span className="font-medium">{currentAssignee.first_name} {currentAssignee.last_name}</span>
                        </p>
                    </div>
                )}
            </div>
        </Modal>
    );
};
