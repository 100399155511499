import {Bell, FileText, Folders, InfoIcon, LayoutDashboard} from "lucide-react";
import {NavItem} from "@/routes/navigation";

export const techNavItems: NavItem[] = [
    {
        name: 'Πίνακας Ελέγχου',
        icon: LayoutDashboard,
        href: '/'
    },
    {
        name: 'Αιτήματα',
        icon: FileText,
        children: [
            { name: 'Όλα τα Αιτήματα', href: '/tickets' },
            { name: 'Δημιουργία Αιτήματος', href: '/tickets/new' },
        ]
    },
    {
        name: 'Υποθέσεις',
        icon: Folders,
        children: [
            { name: 'Όλες οι Υποθέσεις', href: '/cases' },
        ]
    },
    // {
    //     name: 'Αναφορές',
    //     icon: BarChart3,
    //     children: [
    //         { name: 'Αναφορά Αιτημάτων', href: '/reports/tickets' },
    //         { name: 'Αναφορά SLA', href: '/reports/sla' }
    //     ]
    // },
    {
        name: 'Πληροφορίες',
        icon: InfoIcon,
        children: [
            { name: 'Καταστάσεις', href: '/status' }
        ]
    },
    {
        name: 'Ειδοποιήσεις',
        icon: Bell,
        href: '/notifications'
    },
];
