import { client } from '@/lib/httpClient';
import type {
    Ticket,
    TicketResponse,
    CreateTicketRequest,
    TicketFilters,
    TicketsListResponse, UpdateTicketPayload
} from "@/types/ticket.types";
import { createTicketSchema } from '@/schemas/ticket.schemas';
import {AssignmentAudit, StatusAudit} from "@/types/audit.types";

export const ticketsApi = {
    getAll: async (filters?: TicketFilters) => {
        const response = await client.get<TicketsListResponse>(
            '/api/v1/tickets/',
            { params: filters }
        );
        return response.data;
    },

    getById: async (id: string) => {
        const response = await client.get<Ticket>(
            `/api/v1/tickets/${id}`
        );
        return response.data;
    },

    create: async (data: CreateTicketRequest) => {
        const validated = createTicketSchema.parse(data);
        const response = await client.post<TicketResponse>(
            '/api/v1/tickets/',
            validated
        );
        return response.data;
    },

    update: async (id: string, data: UpdateTicketPayload) => {
        const response = await client.put<TicketResponse>(
            `/api/v1/tickets/${id}`,
            data
        );
        return response.data;
    },

    delete: async (id: string) => {
        const response = await client.delete<TicketResponse>(
            `/api/v1/tickets/${id}`
        );
        return response.data;
    },

    updateStatus: async (id: string, data: { status_id: string }) => {
        const response = await client.put<TicketResponse>(
            `/api/v1/tickets/${id}/status`,
            data
        );
        return response.data;
    },

    updateAssignee: async (id: string, data: { assignee_id: string }) => {
        const response = await client.put<TicketResponse>(
            `/api/v1/tickets/${id}/assignee`,
            data
        );
        return response.data;
    },

    getStatusAudits: async (id: string) => {
        const response = await client.get<StatusAudit[]>(
            `/api/v1/tickets/${id}/status_audits`
        );
        return response.data;
    },

    getAssignmentAudits: async (id: string) => {
        const response = await client.get<AssignmentAudit[]>(
            `/api/v1/tickets/${id}/assignment_audits`
        );
        return response.data;
    },

    updateRatings: async (id: string, data: { rating_1: number; rating_2: number; rating_3: number }) => {
        const response = await client.put<TicketResponse>(
            `/api/v1/tickets/${id}/ratings`,
            data
        );
        return response.data;
    },
};
