import Checkbox from "@/components/ui/Checkbox/Checkbox";
import {CompanyProduct} from "@/types/company.types";
import Skeleton from "react-loading-skeleton";
import React from "react";

export const ProductCheckboxGroup: React.FC<{
    isLoading: boolean;
    products: CompanyProduct[];
    selectedProducts: string[];
    onChange: (ids: string[]) => void;
}> = ({ isLoading, products, selectedProducts, onChange }) => {
    if (isLoading) {
        return (
            <div className="space-y-4">
                {[1, 2, 3].map((i) => (
                    <div key={i} className="bg-white rounded-lg border border-gray-200 p-4">
                        <Skeleton className="h-6 w-3/4" />
                        <Skeleton className="h-4 w-1/2 mt-2" />
                    </div>
                ))}
            </div>
        );
    }

    if (products.length === 0) {
        return (
            <div className="bg-surface border border-dashed border-gray-300 rounded-lg p-6">
                <div className="text-center">
                    <p className="text-sm text-gray-500">
                        Δεν βρέθηκαν προϊόντα για την επιλεγμένη εταιρεία
                    </p>
                </div>
            </div>
        );
    }

    return (
        <div className="grid grid-cols-2 gap-2">
            {products.map(product => (
                <div key={product.product_client_id} className="bg-white rounded-lg border border-gray-200 p-4 hover:border-primary-300 transition-colors">
                    <Checkbox
                        size="md"
                        label={product.product.product_name}
                        /*description={`${product.product.product_code} - ${product.product.description}`}*/
                        checked={selectedProducts.includes(product.product_client_id)}
                        onChange={(e) => {
                            const newSelected = e.target.checked
                                ? [...selectedProducts, product.product_client_id]
                                : selectedProducts.filter(id => id !== product.product_client_id);
                            onChange(newSelected);
                        }}
                    />
                </div>
            ))}
        </div>
    );
};
