import { useQuery } from '@tanstack/react-query';
import type {User, UserFilters} from '@/types/user.types';
import { USER_KEYS } from './users.queryKeys';
import { usersApi } from './users.api';
import {PaginatedResponse} from "@/types/api.types";

export const useGetUsersQuery = (filters?: UserFilters) => {
    return useQuery<PaginatedResponse<User>>({
        queryKey: [USER_KEYS.list, filters],
        queryFn: () => usersApi.getAll(filters),
    });
};

export const useGetUserQuery = (id: string) => {
    return useQuery<User>({
        queryKey: USER_KEYS.detail(id),
        queryFn: () => usersApi.getById(id),
        enabled: !!id,
    });
};
