import React from 'react';
import { Star } from 'lucide-react';
import { ButtonSize, ButtonVariant } from "@/components/ui/Button/ButtonSize";
import {Card} from "@/components/ui/Card";
import Button from "@/components/ui/Button";

interface RatingCardProps {
    canRate: boolean;
    ratings: {
        rating_1: number | null;
        rating_2: number | null;
        rating_3: number | null;
    };
    onRate: (ratings: { rating_1: number; rating_2: number; rating_3: number }) => void;
}

export const RatingCard: React.FC<RatingCardProps> = ({ canRate, ratings, onRate }) => {
    const [tempRatings, setTempRatings] = React.useState({
        rating_1: ratings.rating_1 ?? 0,
        rating_2: ratings.rating_2 ?? 0,
        rating_3: ratings.rating_3 ?? 0
    });
    const [hoveredRatings, setHoveredRatings] = React.useState({
        rating_1: 0,
        rating_2: 0,
        rating_3: 0
    });
    const [isEditing, setIsEditing] = React.useState(false);

    const ratingLabels = {
        rating_1: 'Επικοινωνία & Υποστήριξη',
        rating_2: 'Ποιότητα Επίλυσης',
        rating_3: 'Συνολική Εμπειρία'
    };

    const ratingDescriptions = {
        rating_1: 'Πόσο ικανοποιημένοι μείνατε από την επικοινωνία και την υποστήριξη;',
        rating_2: 'Πόσο καλά επιλύθηκε το ζήτημά σας;',
        rating_3: 'Πώς θα βαθμολογούσατε τη συνολική σας εμπειρία;'
    };

    const handleSubmit = () => {
        onRate(tempRatings);
        setIsEditing(false);
    };

    const renderStars = (type: keyof typeof ratings, readOnly: boolean = false) => {
        return (
            <div className="flex items-center space-x-1">
                {[1, 2, 3, 4, 5].map((star) => (
                    <button
                        key={star}
                        disabled={readOnly}
                        className={`${readOnly ? 'cursor-default' : 'cursor-pointer'} 
                        focus:outline-none transition-colors`}
                        onMouseEnter={() => !readOnly && setHoveredRatings(prev => ({
                            ...prev,
                            [type]: star
                        }))}
                        onMouseLeave={() => !readOnly && setHoveredRatings(prev => ({
                            ...prev,
                            [type]: 0
                        }))}
                        onClick={() => !readOnly && setTempRatings(prev => ({
                            ...prev,
                            [type]: star
                        }))}
                    >
                        <Star
                            className={`h-6 w-6 ${
                                star <= (hoveredRatings[type] || tempRatings[type] || 0)
                                    ? 'fill-yellow-400 text-yellow-400'
                                    : 'text-gray-300'
                            } transition-colors`}
                        />
                    </button>
                ))}
            </div>
        );
    };

    return (
        <Card className="p-4 sm:p-6">
            <div className="flex items-center justify-between mb-4">
                <h2 className="text-lg font-semibold">Αξιολόγηση</h2>
                {canRate && !isEditing && !ratings.rating_1 && (
                    <Button
                        variant={ButtonVariant.OUTLINE}
                        size={ButtonSize.SM}
                        onClick={() => setIsEditing(true)}
                    >
                        Προσθήκη Αξιολόγησης
                    </Button>
                )}
            </div>

            <div className="space-y-6">
                {Object.entries(ratingLabels).map(([key, label]) => (
                    <div key={key} className="space-y-2">
                        <div className="flex items-center justify-between">
                            <div>
                                <h3 className="font-medium text-gray-900">{label}</h3>
                                {isEditing && (
                                    <p className="text-sm text-gray-500">
                                        {ratingDescriptions[key as keyof typeof ratingDescriptions]}
                                    </p>
                                )}
                            </div>
                            {renderStars(
                                key as keyof typeof ratings,
                                !isEditing
                            )}
                        </div>
                    </div>
                ))}
            </div>

            {isEditing && (
                <div className="mt-6 flex justify-end space-x-3">
                    <Button
                        variant={ButtonVariant.OUTLINE}
                        size={ButtonSize.SM}
                        onClick={() => {
                            setIsEditing(false);
                            setTempRatings({
                                rating_1: ratings.rating_1 ?? 0,
                                rating_2: ratings.rating_2 ?? 0,
                                rating_3: ratings.rating_3 ?? 0
                            });
                        }}
                    >
                        Ακύρωση
                    </Button>
                    <Button
                        size={ButtonSize.SM}
                        onClick={handleSubmit}
                    >
                        Υποβολή Αξιολόγησης
                    </Button>
                </div>
            )}
        </Card>
    );
};
