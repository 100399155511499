// StatCard.tsx
import React from 'react';
import { ArrowUp, ArrowDown, LucideIcon } from 'lucide-react';
import {Card, CardContent} from "@/components/ui/Card";

interface StatCardProps {
    title: string;
    value: string | number;
    trend?: number;
    trendUp?: boolean;
    icon: LucideIcon;
    suffix?: string;
    className?: string;
    iconClassName?: string;
    trendIsPositive?: boolean; // If true, up trend is good, if false, down trend is good
}

export const StatCard = ({
                             title,
                             value,
                             trend,
                             trendUp,
                             icon: Icon,
                             suffix = '',
                             className = '',
                             iconClassName = '',
                             trendIsPositive = true
                         }: StatCardProps) => {
    const getTrendColor = () => {
        const isPositiveTrend = trendIsPositive ? trendUp : !trendUp;
        return isPositiveTrend ? 'text-success-600' : 'text-error-600';
    };

    return (
        <Card className={className}>
            <CardContent className="p-6">
                <div className="flex items-center justify-between">
                    <div>
                        <p className="text-sm font-medium text-gray-600">{title}</p>
                        <div className="flex items-baseline">
                            <p className="text-2xl font-semibold text-gray-900">
                                {value}{suffix}
                            </p>
                            {trend !== undefined && (
                                <p className={`ml-2 text-sm ${getTrendColor()}`}>
                                    {trendUp ?
                                        <ArrowUp className="inline h-4 w-4"/> :
                                        <ArrowDown className="inline h-4 w-4"/>
                                    }
                                    {Math.abs(trend)}
                                    {suffix}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className={`p-3 rounded-lg ${iconClassName || 'bg-gray-100'}`}>
                        <Icon className={`h-6 w-6 ${iconClassName ? iconClassName.replace('bg-', 'text-') : 'text-gray-600'}`}/>
                    </div>
                </div>
            </CardContent>
        </Card>
    );
};

export default StatCard;
