import {QueryClient, QueryClientProvider} from '@tanstack/react-query'
import {BrowserRouter} from 'react-router-dom'
import AppRoutes from './routes/AppRoutes'
import {Auth0Provider} from "@auth0/auth0-react";
import config from "@/lib/config";
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'


const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 3 * 60 * 1000, // 3 minutes 3 * 60 * 1000
            retry: 3,
            refetchOnWindowFocus: true,
        },
    },
})

function App() {
    return (
        <Auth0Provider domain={config.authServer}
                       clientId={config.authClientId}
                       authorizationParams={{
                           redirect_uri: window.location.origin,
                           audience: config.audience,
                       }}>
            <QueryClientProvider client={queryClient}>
                <BrowserRouter>
                    <AppRoutes/>
                    <ToastContainer autoClose={3000} />
                </BrowserRouter>
                <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
        </Auth0Provider>
    )
}

export default App
