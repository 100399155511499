// src/constants/routes.constants.ts
import {Status} from "@/types/status.types";

export const API_VERSION = 'v1';
export const API_BASE = `/api/${API_VERSION}`;

export const ROUTES = {
    TICKETS: {
        LIST: '/tickets',
        NEW: '/tickets/new',
        DETAILS: (id: string) => `/tickets/${id}`,
        API: {
            BASE: `${API_BASE}/tickets`,
            DETAIL: (id: string) => `${ROUTES.TICKETS.API.BASE}/${id}`,
            MESSAGES: (id: string) => `${ROUTES.TICKETS.API.DETAIL(id)}/messages`,
            ASSIGN: (ticketId: string, userId: string) =>
                `${ROUTES.TICKETS.API.DETAIL(ticketId)}/assign/${userId}`,
            STATUS: (ticketId: string, status: Status) =>
                `${ROUTES.TICKETS.API.DETAIL(ticketId)}/status/${status}`
        }
    },
    CASES: {
        LIST: '/cases',
        NEW: '/cases/new',
        DETAILS: (id: string) => `/cases/${id}`,
        API: {
            BASE: `${API_BASE}/cases`,
            DETAIL: (id: string) => `/api/v1/cases/${id}`,
            STATUS: (id: string, statusId: string) => `/api/v1/cases/${id}/status/${statusId}`,
            ASSIGN: (id: string, userId: string) => `/api/v1/cases/${id}/assign/${userId}`,
            LINK_TICKETS: (id: string) => `/api/v1/cases/${id}/tickets`,
            UNLINK_TICKETS: (id: string) => `/api/v1/cases/${id}/tickets`,
            DUPLICATE_TICKET: (caseId: string) => `/api/v1/cases/${caseId}/duplicate_ticket`,
            CATEGORIES: `${API_BASE}/cases/categories`
        }
    },
    CATEGORIES: {
        LIST: '/categories',
        NEW: '/categories/new',
        DETAILS: (id: string) => `/categories/${id}`,
        API: {
            BASE: `${API_BASE}/categories`,
            DETAIL: (id: string) => `${ROUTES.CATEGORIES.API.BASE}/${id}`
        }
    },
    PRIORITIES: {
        LIST: '/priorities',
        NEW: '/priorities/new',
        DETAILS: (id: string) => `/priorities/${id}`,
        API: {
            BASE: `${API_BASE}/priorities`,
            DETAIL: (id: string) => `${ROUTES.PRIORITIES.API.BASE}/${id}`
        }
    },
    COMPANIES: {
        LIST: '/companies',
        NEW: '/companies/new',
        DETAILS: (id: string) => `/companies/${id}`,
        API: {
            BASE: `${API_BASE}/companies`,
            DETAIL: (id: string) => `${ROUTES.COMPANIES.API.BASE}/${id}`,
            PRODUCTS: (id: string) => `${ROUTES.COMPANIES.API.DETAIL(id)}/products`,
            SLA: (id: string) => `${ROUTES.COMPANIES.API.DETAIL(id)}/sla`
        }
    },
    DEPARTMENTS: {
        LIST: '/departments',
        NEW: '/departments/new',
        DETAILS: (id: string) => `/departments/${id}`,
        API: {
            BASE: `${API_BASE}/departments`,
            DETAIL: (id: string) => `${ROUTES.DEPARTMENTS.API.BASE}/${id}`
        }
    },
    USERS: {
        LIST: '/users',
        NEW: '/users/new',
        DETAILS: (id: string) => `/users/${id}`,
        API: {
            BASE: `${API_BASE}/users`,
            DETAIL: (id: string) => `${ROUTES.USERS.API.BASE}/${id}`,
            ROLES: (id: string) => `${ROUTES.USERS.API.DETAIL(id)}/roles`,
            HEARTBEAT: `${API_BASE}/users/heartbeat`
        }
    },
    ROLES: {
        LIST: '/roles',
        DETAILS: (id: string) => `/roles/${id}`,
        API: {
            BASE: `${API_BASE}/roles`,
            DETAIL: (id: string) => `${ROUTES.ROLES.API.BASE}/${id}`
        }
    },
    STATUS: {
        LIST: '/status',
        DETAILS: (id: string) => `/status/${id}`,
        API: {
            BASE: `${API_BASE}/status`,
            DETAIL: (id: string) => `${ROUTES.STATUS.API.BASE}/${id}`
        }
    },
    SLA: {
        LIST: '/sla',
        NEW: '/sla/new',
        DETAILS: (id: string) => `/sla/${id}`,
        API: {
            BASE: `${API_BASE}/sla`,
            DETAIL: (id: string) => `${ROUTES.SLA.API.BASE}/${id}`
        }
    },
    PRODUCT_TYPES: {
        LIST: '/product-types',
        NEW: '/product-types/new',
        DETAILS: (id: string) => `/product-types/${id}`,
        API: {
            BASE: `${API_BASE}/product-types`,
            DETAIL: (id: string) => `${ROUTES.PRODUCT_TYPES.API.BASE}/${id}`
        }
    },

    PRODUCTS: {
        LIST: '/products',
        NEW: '/products/new',
        DETAILS: (id: string) => `/products/${id}`,
        API: {
            BASE: `${API_BASE}/products`,
            DETAIL: (id: string) => `${ROUTES.PRODUCTS.API.BASE}/${id}`,
            CLIENTS: (id: string) => `${ROUTES.PRODUCTS.API.DETAIL(id)}/clients`
        }
    },
} as const;
