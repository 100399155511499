// components/ui/DropdownWithLabel/YearDropdown.tsx
import React from 'react';
import DropdownWithLabel from '@/components/ui/DropdownWithLabel';

interface YearDropdownProps {
    label?: string;
    value?: number;
    onChange?: (value: number) => void;
    error?: string;
    required?: boolean;
    disabled?: boolean;
    placeholder?: string;
    helperText?: string;
}

const years = [2024, 2025];

const YearDropdown = React.forwardRef<HTMLSelectElement, YearDropdownProps>(
    ({
         label = 'Έτος',
         value,
         onChange,
         error,
         required = false,
         disabled = false,
         placeholder = 'Επιλέξτε Έτος',
         helperText,
         ...props
     }, ref) => {
        const [currentValue, setCurrentValue] = React.useState<number>(-1);

        React.useEffect(() => {
            // When value prop changes or when productTypes load
            if (value) {
                setCurrentValue(value);
            } else {
                setCurrentValue(-1);
            }
        }, [value]);

        const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
            const strValue = parseInt(event.target.value);
            const newValue = !isNaN(strValue) ? strValue : -1;
            setCurrentValue(newValue);
            onChange?.(newValue);
        };

        const yearOptions = React.useMemo(() => {
            return [
                { value: '', label: placeholder },
                ...years.map((year) => ({
                    value: year,
                    label: year.toString(),
                }))
            ];
        }, [placeholder]);

        return (
            <DropdownWithLabel
                ref={ref}
                label={label}
                options={yearOptions}
                value={currentValue}
                onChange={handleChange}
                error={error}
                disabled={disabled}
                required={required}
                helperText={helperText}
                {...props}
            />
        );
    }
);

YearDropdown.displayName = 'YearDropdown';

export default YearDropdown;
