import { Ticket, Briefcase, AtSign, Bell } from 'lucide-react';
import {Badge} from "@/components/ui/Badge";

const NotificationBadge: React.FC<{ type: string }> = ({ type }) => {
    const getConfig = (type: string) => {
        const lowerType = type.toLowerCase();
        const validTypes = ['ticket', 'case', 'mention'];
        return {
            variant: validTypes.includes(lowerType) ? lowerType : 'secondary',
            icon: {
                ticket: Ticket,
                case: Briefcase,
                mention: AtSign,
            }[lowerType] || Bell,
        };
    };

    const config = getConfig(type);
    const Icon = config.icon;

    return (
        <Badge
            variant={config.variant as never} // or extend the BadgeProps type to include your variants
            className="flex items-center gap-1 whitespace-nowrap"
        >
            <Icon className="w-3 h-3" />
            {type}
        </Badge>
    );
};

export default NotificationBadge;
