// src/components/features/tickets/components/TicketDialogs.tsx
import { Fragment, useState } from 'react';
import {Dialog, Input, Transition} from '@headlessui/react';
import { Activity, Search, UserPlus, X } from 'lucide-react';
import { ButtonSize, ButtonVariant } from '@/components/ui/Button/ButtonSize';
import { Avatar } from '@/components/ui/Avatar/Avatar';
import { RoleBadge } from '@/components/ui/Badge/RoleBadge';
import { useUpdateTicketStatusMutation, useUpdateTicketAssigneeMutation } from '../services/tickets.mutations';
import Button from "@/components/ui/Button";
import StatusDropdown from "@/components/ui/DropdownWithLabel/StatusDropdown";
import useUserStore from "@/stores/useUserStore";
import {useGetUsersQuery} from "@/components/features/users/services/users.queries";

interface TicketDialogsProps {
    ticketId: string;
    currentStatus?: string;
}

export function TicketDialogs({ ticketId, currentStatus }: TicketDialogsProps) {
    return (
        <div className="flex items-center space-x-3">
            <AssigneeDialog ticketId={ticketId} />
            <StatusChangeDialog ticketId={ticketId} currentStatus={currentStatus}/>
        </div>
    );
}

// Status Change Dialog Component
interface StatusChangeDialogProps {
    ticketId: string;
    currentStatus?: string;
}

function StatusChangeDialog({ ticketId, currentStatus }: StatusChangeDialogProps) {
    const [open, setOpen] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState<string>(currentStatus || '');

    const updateStatus = useUpdateTicketStatusMutation(ticketId);

    const handleStatusChange = async () => {
        if (!selectedStatus) return;

        await updateStatus.mutateAsync({ status_id: selectedStatus }, {
            onSuccess: () => {
                setOpen(false);
                setSelectedStatus('');
            }
        });
    };

    return (
        <>
            <Button
                variant={ButtonVariant.SECONDARY}
                size={ButtonSize.SM}
                onClick={() => setOpen(true)}
            >
                <Activity className="w-4 h-4 mr-2" />
                Αλλαγή Κατάστασης
            </Button>

            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="relative z-50" onClose={setOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                    <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                                        <button
                                            type="button"
                                            className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                                            onClick={() => setOpen(false)}
                                        >
                                            <span className="sr-only">Close</span>
                                            <X className="h-6 w-6" aria-hidden="true" />
                                        </button>
                                    </div>
                                    <div>
                                        <div className="mt-3 text-center sm:mt-5">
                                            <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                                                Αλλαγή Κατάστασης Ticket
                                            </Dialog.Title>
                                            <div className="mt-4">
                                                <StatusDropdown
                                                    value={selectedStatus}
                                                    onChange={(value) => setSelectedStatus(value)}
                                                    placeholder="Επιλέξτε νέα κατάσταση"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                        <Button
                                            variant={ButtonVariant.SECONDARY}
                                            size={ButtonSize.SM}
                                            onClick={() => setOpen(false)}
                                            className="mt-3 sm:mt-0"
                                        >
                                            Ακύρωση
                                        </Button>
                                        <Button
                                            size={ButtonSize.SM}
                                            onClick={handleStatusChange}
                                            disabled={!selectedStatus || updateStatus.isPending}
                                            className="sm:col-start-2"
                                        >
                                            {updateStatus.isPending ? "Αποθήκευση..." : "Αποθήκευση"}
                                        </Button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    );
}

// Assignee Dialog Component
interface AssigneeDialogProps {
    ticketId: string;
}

function AssigneeDialog({ ticketId }: AssigneeDialogProps) {
    const [open, setOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedUser, setSelectedUser] = useState<string>();
    const user = useUserStore(state => state.user);
    const companyId = user?.company_id;

    const companyUsers = useGetUsersQuery({ company_id: companyId, page_number: 1, page_size: 400 });
    const updateAssignee = useUpdateTicketAssigneeMutation(ticketId);

    const filteredCompanyUsers = companyUsers.data?.data.filter(user => {
        const fullName = `${user.first_name} ${user.last_name}`;
        return fullName.toLowerCase().includes(searchTerm.toLowerCase());
    }
    ) || [];

    const handleAssignment = async () => {
        if (!selectedUser) return;

        await updateAssignee.mutateAsync({ assignee_id: selectedUser }, {
            onSuccess: () => {
                setOpen(false);
                setSelectedUser(undefined);
                setSearchTerm('');
            }
        });
    };

    return (
        <>
            <Button
                variant={ButtonVariant.OUTLINE}
                size={ButtonSize.SM}
                onClick={() => setOpen(true)}
            >
                <UserPlus className="w-4 h-4 mr-2" />
                Ανάθεση
            </Button>

            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="relative z-50" onClose={setOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                    <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                                        <button
                                            type="button"
                                            className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                                            onClick={() => setOpen(false)}
                                        >
                                            <span className="sr-only">Close</span>
                                            <X className="h-6 w-6" aria-hidden="true" />
                                        </button>
                                    </div>
                                    <div>
                                        <div className="mt-3">
                                            <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                                                Ανάθεση Ticket
                                            </Dialog.Title>
                                            <div className="mt-4">
                                                <div className="relative">
                                                    <Input
                                                        placeholder="Αναζήτηση χρήστη..."
                                                        value={searchTerm}
                                                        onChange={(e) => setSearchTerm(e.target.value)}
                                                        className="pr-10"
                                                    />
                                                    <Search className="absolute right-3 top-2.5 h-4 w-4 text-gray-400" />
                                                </div>
                                                <div className="mt-4 max-h-[200px] overflow-y-auto space-y-2">
                                                    {filteredCompanyUsers.map(agent => (
                                                        <div
                                                            key={agent.user_id}
                                                            className={`flex items-center space-x-3 p-2 hover:bg-gray-100 rounded-md cursor-pointer ${
                                                                selectedUser === agent.user_id ? 'bg-gray-100' : ''
                                                            }`}
                                                            onClick={() => setSelectedUser(agent.user_id)}
                                                        >
                                                            <Avatar
                                                                firstName={agent.first_name}
                                                                lastName={agent.last_name}
                                                                roleName={agent.role.role_name}
                                                            />
                                                            <div>
                                                                <p className="font-medium">
                                                                    {`${agent.first_name} ${agent.last_name}`}
                                                                </p>
                                                                <RoleBadge role={agent.role} />
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                        <Button
                                            variant={ButtonVariant.SECONDARY}
                                            size={ButtonSize.SM}
                                            onClick={() => setOpen(false)}
                                            className="mt-3 sm:mt-0"
                                        >
                                            Ακύρωση
                                        </Button>
                                        <Button
                                            size={ButtonSize.SM}
                                            onClick={handleAssignment}
                                            disabled={!selectedUser || updateAssignee.isPending}
                                            className="sm:col-start-2"
                                        >
                                            {updateAssignee.isPending ? "Ανάθεση..." : "Ανάθεση"}
                                        </Button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    );
}
