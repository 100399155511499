import React from 'react';
import SelectMenu from '@/components/ui/SelectMenu';
import type { Company } from '@/types/company.types';
import { useGetCompaniesQuery } from "@/components/features/companies/services/companies.queries";

interface CompanyDropdownProps {
    label?: string;
    value?: string;
    onChange?: (company_id: string) => void;
    error?: string;
    required?: boolean;
    disabled?: boolean;
    placeholder?: string;
    showAllOption?: boolean; // New prop to control visibility of "All" option
}

const CompanyDropdown = React.forwardRef<HTMLButtonElement, CompanyDropdownProps>(
    ({
         label = 'Εταιρεία',
         value,
         onChange,
         error,
         required = false,
         disabled = false,
         placeholder = 'Επιλέξτε εταιρεία',
         showAllOption = false, // Default to false for required fields
         ...props
     }, ref) => {
        const { data: companies, isLoading, isError } = useGetCompaniesQuery();

        const handleChange = (selected: { id: string; label: string }) => {
            onChange?.(selected.id);
        };

        const companyOptions = React.useMemo(() => {
            const options = [];

            // Add "All Companies" option if enabled
            if (showAllOption) {
                options.push({
                    id: '',
                    label: 'Όλες οι εταιρείες'
                });
            }

            if (companies) {
                options.push(
                    ...companies.map((company: Company) => ({
                        id: company.company_id,
                        label: company.company_name,
                    }))
                );
            }

            return options;
        }, [companies, showAllOption]);

        return (
            <SelectMenu
                ref={ref}
                label={label}
                menuItems={companyOptions}
                onChange={handleChange}
                defaultValue={value}
                error={isError ? 'Σφάλμα φόρτωσης εταιρειών' : error}
                disabled={disabled || isLoading}
                required={required}
                placeholder={placeholder}
                searchPlaceholder="Αναζήτηση εταιρείας..."
                {...props}
            />
        );
    }
);

CompanyDropdown.displayName = 'CompanyDropdown';

export default CompanyDropdown;
