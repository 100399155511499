import {useMutation, useQueryClient} from "@tanstack/react-query";
import {toast} from 'react-toastify';
import {ActionStatus} from "@/types/api.types";
import {notificationsApi} from "@/components/features/notifications/services/notifications.api";
import {NOTIFICATION_KEYS} from "@/components/features/notifications/services/notifications.queryKeys";

export const useUpdateNotificationStatusMutation = () => {
    const queryClient = useQueryClient();

    return useMutation<
        ActionStatus,
        Error,
        { id: string; isSeen: boolean }
    >({
        mutationFn: notificationsApi.updateNotificationStatus,
        onSuccess: async (response) => {
            await queryClient.invalidateQueries({queryKey: NOTIFICATION_KEYS.all});
            toast.success('Η κατάσταση ενημερώθηκε επιτυχώς');
            return response;
        },
        onError: (error) => {
            console.error('Update company error:', error);
            toast.error('Σφάλμα κατά την ενημέρωση της κατάστασης');
        }
    });
};
