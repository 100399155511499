import React, { useState, useCallback } from 'react';
import { Search, Link, X, Check, Filter } from 'lucide-react';
import { Card } from "@/components/ui/Card";
import Button from '@/components/ui/Button';
import { ButtonSize, ButtonVariant } from '@/components/ui/Button/ButtonSize';
import StatusBadge from '@/components/ui/Badge/StatusBadge';
import PriorityBadge from '@/components/ui/Badge/PriorityBadge';
import { TimeFormatted } from '@/components/ui/Time/TimeFormatted';
import StatusDropdown from '@/components/ui/DropdownWithLabel/StatusDropdown';
import PriorityDropdown from '@/components/ui/DropdownWithLabel/PriorityDropdown';
import CategoryDropdown from '@/components/ui/DropdownWithLabel/CategoryDropdown';
import ProductDropdown from '@/components/ui/DropdownWithLabel/ProductDropdown';
import CompanyDropdown from '@/components/ui/DropdownWithLabel/CompanyDropdown';
import { useGetTicketsQuery } from '@/components/features/tickets/services/tickets.queries';
import type { Ticket, TicketFilters } from '@/types/ticket.types';
import useDebounce from "@/hooks/useDebounce";
import InputWithLabel from "@/components/ui/InputWithLabel";
import useUserStore from "@/stores/useUserStore";
import { isClient, isMindsysExternalPartner } from "@/utils/auth.utils";

interface TicketLinkingModalProps {
    isOpen: boolean;
    onClose: () => void;
    onLink: (tickets: Ticket[]) => void;
    excludeTicketIds: string[];
}

export const TicketLinkingModal: React.FC<TicketLinkingModalProps> = ({
                                                                          isOpen,
                                                                          onClose,
                                                                          onLink,
                                                                          excludeTicketIds
                                                                      }) => {
    const { user } = useUserStore();
    const roles = user?.roles || [];
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedTickets, setSelectedTickets] = useState<string[]>([]);
    const [showFilters, setShowFilters] = useState(false);
    const [filters, setFilters] = useState<TicketFilters>({
        page_number: 1,
        page_size: 10
    });

    const debouncedSearch = useDebounce(searchTerm, 500);

    const { data, isLoading, isError } = useGetTicketsQuery({
        ...filters,
        ticket_number: /^\d+$/.test(debouncedSearch) ? debouncedSearch : undefined,
        ticket_text: /^\d+$/.test(debouncedSearch) ? undefined : debouncedSearch || undefined
    });

    const tickets = React.useMemo(() => {
        if (!data?.data) return [];
        return data.data.filter(ticket => !excludeTicketIds.includes(ticket.ticket_id));
    }, [data, excludeTicketIds]);

    const handleToggleTicket = useCallback((ticketId: string) => {
        setSelectedTickets(prev =>
            prev.includes(ticketId)
                ? prev.filter(id => id !== ticketId)
                : [...prev, ticketId]
        );
    }, []);

    const handleFilterChange = useCallback((key: keyof TicketFilters, value: string) => {
        setFilters(prev => ({
            ...prev,
            [key]: value || undefined,
            page_number: 1
        }));
    }, []);

    const handleClearFilters = useCallback(() => {
        setFilters({
            page_number: 1,
            page_size: filters.page_size
        });
        setSearchTerm('');

        // Force clear dropdown values
        const dropdowns = document.querySelectorAll('select');
        dropdowns.forEach(dropdown => {
            dropdown.value = '';
        });
    }, [filters.page_size]);

    const handleSubmit = useCallback(() => {
        const selectedTicketObjects = tickets.filter(ticket =>
            selectedTickets.includes(ticket.ticket_id)
        );
        onLink(selectedTicketObjects);
        onClose();
    }, [selectedTickets, tickets, onLink, onClose]);

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 z-50 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center">
                {/* Backdrop */}
                <div
                    className="fixed inset-0 bg-black bg-opacity-25 transition-opacity"
                    onClick={onClose}
                />

                {/* Modal */}
                <div className="relative w-full max-w-3xl p-4 sm:p-6">
                    <Card className="bg-white shadow-xl">
                        {/* Header */}
                        <div className="flex items-center justify-between p-4 sm:p-6 border-b border-gray-200">
                            <div>
                                <h3 className="text-lg font-medium text-gray-900 sm:text-xl">
                                    Σύνδεση Αιτημάτων
                                </h3>
                                <p className="mt-1 text-sm text-gray-500">
                                    Επιλέξτε τα αιτήματα που θέλετε να συνδέσετε με την υπόθεση
                                </p>
                            </div>
                            <button
                                onClick={onClose}
                                className="text-gray-400 hover:text-gray-500"
                            >
                                <X className="h-5 w-5" />
                            </button>
                        </div>

                        {/* Search and Filters */}
                        <div className="p-4 sm:p-6 border-b border-gray-200">
                            <div className="flex flex-col gap-4">
                                <div className="flex items-center gap-4">
                                    <div className="flex-1">
                                        <InputWithLabel
                                            label="Αναζήτηση"
                                            placeholder="Αναζήτηση με αριθμό αιτήματος ή τίτλο/περιγραφή..."
                                            value={searchTerm}
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                            icon={Search}
                                        />
                                    </div>
                                    <Button
                                        variant={ButtonVariant.OUTLINE}
                                        size={ButtonSize.SM}
                                        onClick={() => setShowFilters(!showFilters)}
                                        className="mt-6"
                                    >
                                        <Filter className="h-4 w-4 mr-2" />
                                        Φίλτρα
                                    </Button>
                                </div>

                                {/* Expandable Filters */}
                                {showFilters && (
                                    <div className="bg-gray-50 rounded-lg p-4 space-y-4">
                                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                                            {(!isClient(roles) || !isMindsysExternalPartner(roles)) && (
                                                <>
                                                    <CompanyDropdown
                                                        value={filters.company_id ?? ''}
                                                        onChange={(value) => handleFilterChange('company_id', value)}
                                                        placeholder="Όλες οι εταιρίες"
                                                    />
                                                    <ProductDropdown
                                                        value={filters.product_id ?? ''}
                                                        onChange={(value) => handleFilterChange('product_id', value)}
                                                        placeholder="Όλα τα προϊόντα"
                                                    />
                                                </>
                                            )}
                                            <StatusDropdown
                                                value={filters.status_id ?? ''}
                                                onChange={(value) => handleFilterChange('status_id', value)}
                                                placeholder="Όλες οι καταστάσεις"
                                            />
                                            <PriorityDropdown
                                                value={filters.priority_id ?? ''}
                                                onChange={(value) => handleFilterChange('priority_id', value)}
                                                placeholder="Όλες οι προτεραιότητες"
                                            />
                                            <CategoryDropdown
                                                value={filters.category_id ?? ''}
                                                onChange={(value) => handleFilterChange('category_id', value)}
                                                placeholder="Όλες οι κατηγορίες"
                                            />
                                        </div>
                                        <div className="flex justify-end">
                                            <Button
                                                variant={ButtonVariant.OUTLINE}
                                                onClick={handleClearFilters}
                                            >
                                                Καθαρισμός Φίλτρων
                                            </Button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>

                        {/* Ticket List */}
                        <div className="max-h-[400px] overflow-y-auto p-4 sm:p-6">
                            {isLoading ? (
                                <div className="text-center py-12">
                                    <p className="text-sm text-gray-500">Φόρτωση...</p>
                                </div>
                            ) : isError ? (
                                <div className="text-center py-12">
                                    <p className="text-sm text-error-500">Σφάλμα φόρτωσης δεδομένων</p>
                                </div>
                            ) : tickets.length === 0 ? (
                                <div className="text-center py-12">
                                    <p className="text-sm text-gray-500">
                                        Δεν βρέθηκαν αιτήματα με τα επιλεγμένα κριτήρια
                                    </p>
                                </div>
                            ) : (
                                <div className="space-y-3">
                                    {tickets.map(ticket => (
                                        <div
                                            key={ticket.ticket_id}
                                            onClick={() => handleToggleTicket(ticket.ticket_id)}
                                            className={`
                                                flex items-start justify-between p-4 rounded-lg cursor-pointer
                                                transition-colors duration-200
                                                ${selectedTickets.includes(ticket.ticket_id)
                                                ? 'bg-primary-50 border-2 border-primary-500'
                                                : 'bg-gray-50 hover:bg-gray-100'
                                            }
                                            `}
                                        >
                                            <div className="flex-1 min-w-0">
                                                <div className="flex items-center gap-2 flex-wrap">
                                                    <span className="font-medium">#{ticket.ticket_number}</span>
                                                    <StatusBadge status={ticket.status.status_name} />
                                                    <PriorityBadge priority={ticket.priority.priority_name} />
                                                </div>
                                                <h4 className="mt-1 text-sm font-medium text-gray-900 truncate">
                                                    {ticket.title}
                                                </h4>
                                                <div className="mt-1 flex items-center gap-2 text-xs text-gray-500">
                                                    <span>{ticket.company.company_name}</span>
                                                    <span>•</span>
                                                    <TimeFormatted date={ticket.created_at} />
                                                </div>
                                            </div>
                                            {selectedTickets.includes(ticket.ticket_id) && (
                                                <div className="ml-4 rounded-full bg-primary-500 p-1">
                                                    <Check className="h-4 w-4 text-white" />
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>

                        {/* Footer */}
                        <div className="border-t border-gray-200 p-4 sm:p-6">
                            <div className="flex flex-col-reverse gap-3 sm:flex-row sm:justify-end">
                                <Button
                                    variant={ButtonVariant.OUTLINE}
                                    size={ButtonSize.LG}
                                    onClick={onClose}
                                    className="w-full sm:w-auto"
                                >
                                    Ακύρωση
                                </Button>
                                <Button
                                    variant={ButtonVariant.PRIMARY}
                                    size={ButtonSize.LG}
                                    onClick={handleSubmit}
                                    disabled={selectedTickets.length === 0}
                                    className="w-full sm:w-auto"
                                >
                                    <Link className="h-4 w-4 mr-2" />
                                    Σύνδεση {selectedTickets.length} Αιτημάτων
                                </Button>
                            </div>
                        </div>
                    </Card>
                </div>
            </div>
        </div>
    );
};

export default TicketLinkingModal;
