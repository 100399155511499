import React, { Fragment, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react';
import { Eye, Copy, MoreVertical, Unlink } from 'lucide-react';
import type { CaseTicket } from '@/types/case.types';
import StatusBadge from '@/components/ui/Badge/StatusBadge';
import PriorityBadge from '@/components/ui/Badge/PriorityBadge';
import { TimeFormatted } from '@/components/ui/Time/TimeFormatted';
import { ROUTES } from '@/constants/routes.constants';
import { mapStatusToEnum } from "@/types/status.types";
import { mapPriorityToEnum } from "@/types/priority.types";
import { ButtonVariant } from '@/components/ui/Button/ButtonSize';
import useUserStore from "@/stores/useUserStore";
import { isClient } from "@/utils/auth.utils";
import Button from "@/components/ui/Button";
import { TicketDuplicateDialog } from './TicketDuplicateDialog';

const classNames = (...classes: string[]) => {
    return classes.filter(Boolean).join(' ');
};

interface CaseTicketsListProps {
    tickets: CaseTicket[];
    caseId: string;
    onUnlinkTicket?: (ticketId: string) => Promise<void>;
}

export const CaseTicketsList: React.FC<CaseTicketsListProps> = ({
                                                                    tickets,
                                                                    caseId,
                                                                    onUnlinkTicket
                                                                }) => {
    const navigate = useNavigate();
    const userStore = useUserStore(state => state.user);
    const isClientUser = isClient(userStore.roles);

    const [selectedTicket, setSelectedTicket] = useState<string | null>(null);
    const [isDuplicateModalOpen, setIsDuplicateModalOpen] = useState(false);

    if (tickets.length === 0) {
        return (
            <div className="p-6 text-center text-gray-500">
                Δεν υπάρχουν συνδεδεμένα αιτήματα
            </div>
        );
    }

    return (
        <>
            <div className="divide-y divide-gray-200">
                {tickets.map((caseTicket) => {
                    const ticket = caseTicket.ticket;
                    return (
                        <div
                            key={ticket.ticket_id}
                            className="p-4 sm:p-6 hover:bg-gray-50 transition-colors"
                        >
                            <div className="flex items-center justify-between">
                                <div className="min-w-0 flex-1">
                                    <div className="flex items-center space-x-3">
                                        <span className="text-sm font-medium text-primary-600">
                                            #{ticket.ticket_number}
                                        </span>
                                        <StatusBadge status={mapStatusToEnum(ticket.status.status_name)} />
                                        <PriorityBadge priority={mapPriorityToEnum(ticket.priority.priority_name)} />
                                    </div>
                                    <h3 className="mt-2 text-base font-medium text-gray-900">
                                        {ticket.title}
                                    </h3>
                                    <p className="mt-1 text-sm text-gray-500 line-clamp-2">
                                        {ticket.description}
                                    </p>
                                    <div className="mt-2 flex items-center space-x-4 text-sm text-gray-500">
                                        <TimeFormatted date={ticket.created_at} />
                                        {ticket.creator && (
                                            <span>
                                                Από: {ticket.creator.first_name} {ticket.creator.last_name}
                                            </span>
                                        )}
                                    </div>
                                </div>
                                <div className="ml-4 flex items-center space-x-2">
                                    {/* View Button */}
                                    <Button
                                        variant={ButtonVariant.OUTLINE}
                                        onClick={() => navigate(ROUTES.TICKETS.DETAILS(ticket.ticket_id))}
                                    >
                                        <Eye className="h-4 w-4" />
                                    </Button>

                                    {/* Actions Menu */}
                                    {!isClientUser && (
                                        <Menu as="div" className="relative inline-block text-left">
                                            <Menu.Button
                                                className={classNames(
                                                    "inline-flex items-center justify-center rounded-md p-2",
                                                    "text-gray-500 hover:text-gray-600 hover:bg-gray-100",
                                                    "focus:outline-none focus:ring-2 focus:ring-primary-500"
                                                )}
                                            >
                                                <MoreVertical className="h-4 w-4" />
                                            </Menu.Button>

                                            <Transition
                                                as={Fragment}
                                                enter="transition ease-out duration-100"
                                                enterFrom="transform opacity-0 scale-95"
                                                enterTo="transform opacity-100 scale-100"
                                                leave="transition ease-in duration-75"
                                                leaveFrom="transform opacity-100 scale-100"
                                                leaveTo="transform opacity-0 scale-95"
                                            >
                                                <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                    <div className="py-1">
                                                        <Menu.Item>
                                                            {({ active }) => (
                                                                <button
                                                                    onClick={() => {
                                                                        setSelectedTicket(ticket.ticket_id);
                                                                        setIsDuplicateModalOpen(true);
                                                                    }}
                                                                    className={classNames(
                                                                        active ? 'bg-gray-100' : '',
                                                                        'flex w-full items-center px-4 py-2 text-sm text-gray-700 whitespace-nowrap'
                                                                    )}
                                                                >
                                                                    <Copy className="mr-3 h-4 w-4" />
                                                                    Δημιουργία Αντιγράφου
                                                                </button>
                                                            )}
                                                        </Menu.Item>
                                                        {onUnlinkTicket && (
                                                            <Menu.Item>
                                                                {({ active }) => (
                                                                    <button
                                                                        onClick={() => onUnlinkTicket(ticket.ticket_id)}
                                                                        className={classNames(
                                                                            active ? 'bg-gray-100' : '',
                                                                            'flex w-full items-center px-4 py-2 text-sm text-red-600'
                                                                        )}
                                                                    >
                                                                        <Unlink className="mr-3 h-4 w-4" />
                                                                        Αποσύνδεση
                                                                    </button>
                                                                )}
                                                            </Menu.Item>
                                                        )}
                                                    </div>
                                                </Menu.Items>
                                            </Transition>
                                        </Menu>
                                    )}
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>

            {selectedTicket && (
                <TicketDuplicateDialog
                    caseId={caseId}
                    ticketId={selectedTicket}
                    open={isDuplicateModalOpen}
                    onClose={() => {
                        setIsDuplicateModalOpen(false);
                        setSelectedTicket(null);
                    }}
                />
            )}
        </>
    );
};
