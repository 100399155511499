import React from 'react';
import {type ColumnDef} from '@tanstack/react-table';
import {Eye, EyeOff} from 'lucide-react';
import ColumnTitle from '@/components/ui/DataTable/ColumnTitle';
import {TimeFormatted} from '@/components/ui/Time/TimeFormatted';
import DataTable from "@/components/ui/DataTable";
import {PageTitle} from "@/components/layouts/PageLayout/PageTitle";
import Button from "@/components/ui/Button";
import {ButtonVariant} from "@/components/ui/Button/ButtonSize";
import type {Notification} from '@/types/notification.types';
import {useGetUserNotificationsQuery} from "@/components/features/notifications/services/notifications.queries";
import {
    useUpdateNotificationStatusMutation
} from "@/components/features/notifications/services/notifications.mutations";
import NotificationBadge from "@/components/features/notifications/components/NotificationBadge";

interface NotificationRow {
    id: string;
    type: string;
    description: string;
    is_seen: boolean;
    created_at: string;
}

const mapNotificationToRow = (notification: Notification): NotificationRow => ({
    id: notification.notification_recipient_id,
    type: notification.notification.notification_type.notification_type_name,
    description: notification.notification.description,
    is_seen: notification.is_seen,
    created_at: notification.created_at,
});

const NotificationsList = () => {
    const {mutateAsync: updateNotificationStatus} = useUpdateNotificationStatusMutation();
    const [filters, setFilters] = React.useState({
        page_number: 1,
        page_size: 10,
        is_seen: undefined as boolean | undefined
    });

    const columns: ColumnDef<NotificationRow, string>[] = [
        {
            id: 'notification',
            header: ({column}) => (
                <ColumnTitle title="Ειδοποίηση" isSortable column={column}/>
            ),
            accessorFn: (row) => row.description,
            cell: ({row}) => (
                <div className={`flex items-start space-x-3 py-2 ${!row.original.is_seen ? 'bg-blue-50/50' : ''}`}>
                    {!row.original.is_seen && (
                        <div className="w-2 h-2 mt-2 flex-shrink-0 bg-blue-600 rounded-full"/>
                    )}
                    <div className="flex-1 min-w-0">
                        <p className={`text-sm ${!row.original.is_seen ? 'font-medium text-gray-900' : 'text-gray-600'}`}>
                            {row.original.description}
                        </p>
                        <div className="mt-1 flex items-center gap-2 text-xs text-gray-500">
                            <NotificationBadge type={row.original.type}/>
                            <span>•</span>
                            <TimeFormatted date={row.original.created_at}/>
                        </div>
                    </div>
                    <button
                        onClick={() => handleNotificationStatusChange(row.original.id, !row.original.is_seen)}
                        className="flex-shrink-0 p-1 rounded-full hover:bg-gray-100 transition-colors duration-150"
                    >
                        {row.original.is_seen ?
                            <EyeOff className="w-4 h-4 text-gray-400 hover:text-gray-600"/> :
                            <Eye className="w-4 h-4 text-gray-400 hover:text-gray-600"/>
                        }
                    </button>
                </div>
            ),
        }
    ];

    const FiltersPanel = () => (
        <div className="bg-white p-4 rounded-lg shadow-sm border border-gray-200">
            <div className="flex flex-col sm:flex-row gap-4">
                <div className="flex flex-1 gap-2">
                    <Button
                        variant={!filters.is_seen ? ButtonVariant.PRIMARY : ButtonVariant.OUTLINE}
                        onClick={() => handleFilterChange(!filters.is_seen ? undefined : false)}
                        className="flex-1 flex items-center justify-center gap-2"
                    >
                        <div className={`w-2 h-2 rounded-full ${!filters.is_seen ? 'bg-white' : 'bg-blue-600'}`} />
                        Μη αναγνωσμένες
                    </Button>
                    <Button
                        variant={filters.is_seen ? ButtonVariant.PRIMARY : ButtonVariant.OUTLINE}
                        onClick={() => handleFilterChange(filters.is_seen ? undefined : true)}
                        className="flex-1 flex items-center justify-center gap-2"
                    >
                        <Eye className="w-4 h-4" />
                        Αναγνωσμένες
                    </Button>
                </div>
                <Button
                    variant={ButtonVariant.GHOST}
                    onClick={handleClearFilters}
                    className="text-gray-600"
                >
                    Καθαρισμός φίλτρων
                </Button>
            </div>
        </div>
    );

    const [searchTerm, setSearchTerm] = React.useState('');

    const {data, isLoading, isError} = useGetUserNotificationsQuery(filters);

    const notifications = React.useMemo(() => {
        if (!data?.data) return [];
        return data.data.map(mapNotificationToRow);
    }, [data]);

    const handleFilterChange = (is_seen: boolean | undefined) => {
        setFilters(prev => ({
            ...prev,
            is_seen,
            page_number: 1
        }));
    };

    const handleClearFilters = () => {
        setFilters({
            page_number: 1,
            page_size: filters.page_size,
            is_seen: undefined
        });
        setSearchTerm('');
    };

    const handleNotificationStatusChange = async (id: string, isSeen: boolean) => {
        try {
            await updateNotificationStatus({id, isSeen});
        } catch (error) {
            console.log(error);
        }
    };

    const handlePageChange = (pageIndex: number, pageSize: number) => {
        setFilters(prev => ({
            ...prev,
            page_number: pageIndex + 1,
            page_size: pageSize
        }));
    };

    if (isError) {
        return (
            <div className="h-full w-full flex items-center justify-center">
                <p className="text-error-500">Σφάλμα φόρτωσης ειδοποιήσεων</p>
            </div>
        );
    }

    return (
        <div className="h-full w-full mx-auto p-4 sm:p-6 lg:p-8">
            <PageTitle
                title="Ειδοποιήσεις"
                description="Διαχείριση και παρακολούθηση των ειδοποιήσεών σας"
            />

            <div className="mb-6">
                <FiltersPanel />
            </div>

            <div className="bg-white rounded-lg shadow-sm border border-gray-200">
                <DataTable
                    columns={columns}
                    data={notifications}
                    enableGlobalFilter={false}
                    enableColumnFilters={false}
                    isLoading={isLoading}
                    serverPagination={data ? {
                        totalPages: data.total_pages,
                        totalResults: data.total_results,
                        onPageChange: handlePageChange
                    } : undefined}
                    initialState={{
                        pagination: {
                            pageSize: filters.page_size,
                            pageIndex: filters.page_number - 1
                        }
                    }}
                    className="border-none shadow-none"
                />
            </div>
        </div>
    );
};

export default NotificationsList;
