import { Menu } from 'lucide-react';
import useGlobalStore from "@/stores/useGlobalStore";
import ProfileDropdown from "@/components/ui/ProfileDropdown";
import { SearchDropdown } from "@/components/ui/SearchDropdown/SearchDropdown";
import NotificationsDropdown from "@/components/features/notifications/components/NotificationsDropdown";

export default function Header() {
    const { toggleSidebar } = useGlobalStore();

    return (
        <header className="top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm">
            <button
                onClick={() => toggleSidebar(true)}
                className="text-gray-500 focus:outline-none md:hidden"
            >
                <Menu className="h-6 w-6" />
            </button>

            {/* Search */}
            <div className="flex flex-1 gap-x-4">
                <div className="flex flex-1 items-center">
                    <SearchDropdown />
                </div>
            </div>

            {/* Right section */}
            <div className="flex items-center gap-x-4">
                <NotificationsDropdown />
                <ProfileDropdown />
            </div>
        </header>
    );
}
