import React from 'react';
import { useParams } from 'react-router-dom';
import {
    Calendar,
    Clock,
    Ticket as TicketIcon,
    Tag,
    UserCog,
    PenLine,
    FileEdit
} from 'lucide-react';

import { PageHeader } from "@/components/layouts/PageLayout/PageHeader";
import StatusBadge from "@/components/ui/Badge/StatusBadge";
import PriorityBadge from "@/components/ui/Badge/PriorityBadge";
import { Avatar } from '@/components/ui/Avatar/Avatar';
import { TimeFormatted } from '@/components/ui/Time/TimeFormatted';
import Button from "@/components/ui/Button";
import { ButtonSize, ButtonVariant } from "@/components/ui/Button/ButtonSize";

import { mapStatusToEnum } from "@/types/status.types";
import { mapPriorityToEnum } from "@/types/priority.types";
import useUserStore from "@/stores/useUserStore";
import { isClient } from "@/utils/auth.utils";
import { useGetCaseQuery } from "@/components/features/cases/services/cases.queries";
import {
    useUpdateCaseMutation
} from "@/components/features/cases/services/cases.mutations";
import { CaseEditModal } from "@/components/features/cases/components/CaseEditModal";
import { CaseStatusModal } from "@/components/features/cases/components/CaseStatusModal";
import { CaseTicketsList } from "@/components/features/cases/components/CaseTicketsList";
import { CaseAssigneeModal } from "@/components/features/cases/components/CaseAssigneeModal";
import { CombinedTicketTimeline } from "@/components/features/cases/components/CombinedTicketTimeline";
import {TicketLinkingModal} from "@/pages/Cases/TicketLinkingModal";
import type { Ticket } from '@/types/ticket.types'; // Explicitly import the type


const CaseDetails = () => {
    const { id } = useParams<{ id: string }>();
    const caseId = id ?? '';

    const userStore = useUserStore(state => state.user);
    const isClientUser = isClient(userStore.roles);

    // Modal states
    const [isEditModalOpen, setIsEditModalOpen] = React.useState(false);
    const [isStatusModalOpen, setIsStatusModalOpen] = React.useState(false);
    const [isAssigneeModalOpen, setIsAssigneeModalOpen] = React.useState(false);
    const [isLinkTicketsModalOpen, setIsLinkTicketsModalOpen] = React.useState(false);

    // Queries and Mutations
    const { data: caseData, isLoading } = useGetCaseQuery(caseId);
    const updateCase = useUpdateCaseMutation(caseId);

    const handleLinkTickets = async (tickets: Ticket[]): Promise<void> => {
        if (!caseData) return;

        try {
            const ticketIds = tickets.map(ticket => ticket.ticket_id);
            await updateCase.mutateAsync({
                title: caseData.title,
                description: caseData.description,
                category_id: caseData.category.category_id,
                priority_id: caseData.priority.priority_id,
                status_id: caseData.status.status_id,
                assignee_id: caseData.assignee?.user_id,
                tickets: [...caseData.tickets.map(t => t.ticket.ticket_id), ...ticketIds]
            });
            setIsLinkTicketsModalOpen(false);
        } catch (error) {
            console.error('Error linking tickets:', error);
        }
    };

    const handleUnlinkTickets = async (ticketId: string): Promise<void> => {
        if (!caseData) return;

        try {
            await updateCase.mutateAsync({
                title: caseData.title,
                description: caseData.description,
                category_id: caseData.category.category_id,
                priority_id: caseData.priority.priority_id,
                status_id: caseData.status.status_id,
                assignee_id: caseData.assignee?.user_id,
                tickets: caseData.tickets
                    .map(t => t.ticket.ticket_id)
                    .filter(id => id !== ticketId)
            });
        } catch (error) {
            console.error('Error unlinking ticket:', error);
        }
    };

    if (isLoading || !caseData) {
        return (
            <div className="flex items-center justify-center min-h-screen">
                <div className="animate-pulse text-gray-600">Φόρτωση...</div>
            </div>
        );
    }

    return (
        <div className="min-h-screen bg-gray-100">
            <PageHeader
                title={`Υπόθεση #${caseData.case_number_prefixed} - ${caseData.title}`}
                description={(
                    <div className="flex items-center space-x-2 mt-1 flex-wrap">
                        <StatusBadge status={mapStatusToEnum(caseData.status.status_name)} />
                        <PriorityBadge priority={mapPriorityToEnum(caseData.priority.priority_name)} />
                    </div>
                )}
                backButton
                actions={!isClientUser && (
                    <div className="flex items-center space-x-2">
                        {/* Group management actions together */}
                        <div className="flex items-center gap-2">
                            <Button
                                variant={ButtonVariant.OUTLINE}
                                onClick={() => setIsEditModalOpen(true)}
                            >
                                <FileEdit className="w-4 h-4" />
                            </Button>
                            <Button
                                variant={ButtonVariant.OUTLINE}
                                onClick={() => setIsStatusModalOpen(true)}
                            >
                                <Clock className="w-4 h-4 mr-2" />
                                Αλλαγή Κατάστασης
                            </Button>
                            <Button
                                variant={ButtonVariant.OUTLINE}
                                onClick={() => setIsAssigneeModalOpen(true)}
                            >
                                <UserCog className="w-4 h-4 mr-2" />
                                {caseData.assignee ? 'Αλλαγή Ανάθεσης' : 'Ανάθεση'}
                            </Button>
                        </div>
                    </div>
                )}
            />

            <main className="max-w-7xl mx-auto px-4 py-4 sm:px-6 lg:px-8 sm:py-6">
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 sm:gap-6">
                    {/* Left Column - Main Content */}
                    <div className="lg:col-span-2 space-y-4 sm:space-y-6">
                        {/* Description Card */}
                        <div className="bg-white rounded-lg shadow p-4 sm:p-6">
                            <h2 className="text-lg font-semibold mb-3 sm:mb-4">Περιγραφή</h2>
                            <p className="text-gray-600">{caseData.description}</p>
                        </div>

                        {/* Linked Tickets */}
                        <div className="bg-white rounded-lg shadow">
                            <div className="p-4 sm:p-6 border-b border-gray-200">
                                <div className="flex items-center justify-between">
                                    <h2 className="text-lg font-semibold">Συνδεδεμένα Αιτήματα</h2>
                                    {!isClientUser && (
                                        <Button
                                            variant={ButtonVariant.OUTLINE}
                                            size={ButtonSize.SM}
                                            onClick={() => setIsLinkTicketsModalOpen(true)}
                                        >
                                            <TicketIcon className="w-4 h-4 mr-2" />
                                            Σύνδεση Αιτήματος
                                        </Button>
                                    )}
                                </div>
                            </div>
                            <CaseTicketsList
                                tickets={caseData.tickets}
                                caseId={caseId}
                                onUnlinkTicket={handleUnlinkTickets}  // Add this prop
                            />
                        </div>
                    </div>

                    {/* Right Column - Details & Timeline */}
                    <div className="space-y-4 sm:space-y-6">
                        {/* Case Details */}
                        <div className="bg-white rounded-lg shadow p-4 sm:p-6">
                            <h2 className="text-lg font-semibold mb-4">Στοιχεία Υπόθεσης</h2>
                            <div className="space-y-4">
                                <div className="flex justify-between items-center">
                                    <div className="flex items-center text-gray-500">
                                        <Tag className="w-4 h-4 mr-2"/>
                                        <span>Κατηγορία</span>
                                    </div>
                                    <span className="font-medium">{caseData.category.category_name}</span>
                                </div>

                                <div className="flex items-center justify-between">
                                    <div className="flex items-center text-gray-500">
                                        <PenLine className="w-4 h-4 mr-2"/>
                                        <span>Δημιουργία</span>
                                    </div>
                                    <div className="flex items-center space-x-2">
                                        <Avatar
                                            firstName={caseData.creator.first_name}
                                            lastName={caseData.creator.last_name}
                                            roleName={caseData.creator.role.role_name}
                                            size="sm"
                                        />
                                        <span className="font-medium">
                                            {`${caseData.creator.first_name} ${caseData.creator.last_name}`}
                                        </span>
                                    </div>
                                </div>

                                <div className="flex items-center justify-between">
                                    <div className="flex items-center text-gray-500">
                                        <UserCog className="w-4 h-4 mr-2"/>
                                        <span>Ανάθεση</span>
                                    </div>
                                    <div className="flex items-center space-x-2">
                                        {caseData.assignee ? (
                                            <>
                                                <Avatar
                                                    firstName={caseData.assignee.first_name}
                                                    lastName={caseData.assignee.last_name}
                                                    roleName={caseData.assignee.role.role_name}
                                                    size="sm"
                                                />
                                                <span className="font-medium">
                    {`${caseData.assignee.first_name} ${caseData.assignee.last_name}`}
                </span>
                                            </>
                                        ) : (
                                            <span className="text-gray-400 italic">Μη ανατεθειμένο</span>
                                        )}
                                    </div>
                                </div>

                                <div className="flex justify-between items-center">
                                    <div className="flex items-center text-gray-500">
                                        <Calendar className="w-4 h-4 mr-2"/>
                                        <span>Δημιουργήθηκε</span>
                                    </div>
                                    <TimeFormatted date={caseData.created_at} className="font-medium"/>
                                </div>

                                <div className="flex items-center justify-between">
                                    <div className="flex items-center text-gray-500">
                                        <Clock className="w-4 h-4 mr-2"/>
                                        <span>Ενημερώθηκε</span>
                                    </div>
                                    <TimeFormatted date={caseData.updated_at} className="font-medium"/>
                                </div>
                            </div>
                        </div>

                        {/* Combined Tickets Timeline */}
                        <div className="bg-white rounded-lg shadow p-4 sm:p-6">
                            <h2 className="text-lg font-semibold mb-4">Χρονολόγιο Ενεργειών</h2>
                            <CombinedTicketTimeline tickets={caseData.tickets}/>
                        </div>
                    </div>
                </div>
            </main>

            {/* Modals */}
            <CaseEditModal
                caseData={caseData}
                open={isEditModalOpen}
                setOpen={setIsEditModalOpen}
            />
            <CaseStatusModal
                caseId={caseId}
                currentStatus={caseData.status.status_id}
                open={isStatusModalOpen}
                setOpen={setIsStatusModalOpen}
            />
            <CaseAssigneeModal
                caseId={caseId}
                currentAssignee={caseData.assignee}
                open={isAssigneeModalOpen}
                setOpen={setIsAssigneeModalOpen}
            />
            <TicketLinkingModal
                isOpen={isLinkTicketsModalOpen}
                onClose={() => setIsLinkTicketsModalOpen(false)}
                onLink={handleLinkTickets}
                excludeTicketIds={caseData.tickets.map(t => t.ticket.ticket_id)}
            />
        </div>
    );
};

export default CaseDetails;
