import React from 'react';
import DropdownWithLabel from '@/components/ui/DropdownWithLabel';
import type { Category } from '@/types/category.types';
import { useGetCategoriesQuery } from "@/components/features/categories/services/categories.queries";

interface CategoryDropdownProps {
    label?: string;
    value?: string;
    onChange?: (value: string) => void;
    error?: string;
    required?: boolean;
    disabled?: boolean;
    placeholder?: string;
    defaultCategory?: string;
    helperText?: string;
}

const CategoryDropdown = React.forwardRef<HTMLSelectElement, CategoryDropdownProps>(
    ({
         label = 'Κατηγορία',
         value,
         onChange,
         error,
         required = false,
         disabled = false,
         defaultCategory,
         placeholder = 'Επιλέξτε κατηγορία',
         helperText,
         ...props
     }, ref) => {
        const { data: categories, isLoading, isError } = useGetCategoriesQuery();

        // Only set default category if explicitly provided
        React.useEffect(() => {
            if (categories && !value && defaultCategory) {
                const defaultCategoryItem = categories.find(
                    category => category.category_name.includes(defaultCategory)
                );

                if (defaultCategoryItem) {
                    onChange?.(defaultCategoryItem.category_id);
                }
            }
        }, [categories, value, onChange, defaultCategory]);

        const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
            onChange?.(event.target.value);
        };

        const categoryOptions = React.useMemo(() => {
            if (!categories) return [];

            return categories.map((category: Category) => ({
                value: category.category_id,
                label: category.category_name,
            }));
        }, [categories]);

        if (isError) {
            return (
                <DropdownWithLabel
                    ref={ref}
                    label={label}
                    options={[]}
                    error="Σφάλμα φόρτωσης κατηγοριών"
                    disabled
                    required={required}
                    {...props}
                />
            );
        }

        return (
            <DropdownWithLabel
                ref={ref}
                label={label}
                options={categoryOptions}
                value={value}
                onChange={handleChange}
                error={error}
                disabled={disabled || isLoading}
                required={required}
                placeholder={placeholder}
                helperText={helperText}
                {...props}
            />
        );
    }
);

CategoryDropdown.displayName = 'CategoryDropdown';

export default CategoryDropdown;
