// src/features/tickets/hooks/specializedTickets.hooks.ts
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { ROUTES } from '@/constants/routes.constants';
import type { TicketFilters } from '@/types/ticket.types';
import useGlobalStore from "@/stores/useGlobalStore";
import {TICKET_KEYS} from "@/components/features/tickets/services/tickets.queryKeys";
import {ticketsApi} from "@/components/features/tickets/services/tickets.api";

interface UseSpecializedTicketsOptions {
    pageSize?: number;
    onViewAll?: () => void;
}

const createSpecializedTicketsHook = (baseFilters: TicketFilters) => {
    return function useSpecializedTickets({ pageSize = 5 }: UseSpecializedTicketsOptions = {}) {
        const navigate = useNavigate();
        const { setTicketFilters } = useGlobalStore();

        const query = useQuery({
            queryKey: TICKET_KEYS.list({ ...baseFilters, page_number: 1, page_size: pageSize }),
            queryFn: () => ticketsApi.getAll({ ...baseFilters, page_number: 1, page_size: pageSize }),
            staleTime: 1000 * 60 * 5,
            refetchOnWindowFocus: true
        });

        const handleTicketClick = (ticketId: string) => {
            navigate(ROUTES.TICKETS.DETAILS(ticketId));
        };

        const handleViewAll = () => {
            // Store filters in global store before navigation
            setTicketFilters({ ...baseFilters, page_number: 1, page_size: 10 });
            navigate(ROUTES.TICKETS.LIST);
        };

        return {
            ...query,
            handleTicketClick,
            handleViewAll
        };
    };
}

export const useUnassignedTickets = createSpecializedTicketsHook({
    page_number: 1,
    page_size: 5,
    unassigned: true,
});

export const useCriticalTickets = createSpecializedTicketsHook({
    page_number: 1,
    page_size: 5,
    active: true,
    critical: true
});

export const useActiveTickets = createSpecializedTicketsHook({
    page_number: 1,
    page_size: 5,
    active: true
});

export const useAboutToBreachTickets = createSpecializedTicketsHook({
    page_number: 1,
    page_size: 5,
    active: true,
    about_to_breach: true
});
