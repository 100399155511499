import React from 'react';
import { type ColumnDef } from '@tanstack/react-table';
import { Plus, Search, FileText } from 'lucide-react';
import DataTable from "@/components/ui/DataTable";
import { PageToolbar } from "@/components/layouts/PageLayout/PageToolbar";
import { PageTitle } from "@/components/layouts/PageLayout/PageTitle";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@/constants/routes.constants";
import StatusBadge from "@/components/ui/Badge/StatusBadge";
import PriorityBadge from "@/components/ui/Badge/PriorityBadge";
import type { Case, CaseFilters } from '@/types/case.types';
import useDebounce from "@/hooks/useDebounce";
import { ButtonVariant } from "@/components/ui/Button/ButtonSize";
import InputWithLabel from "@/components/ui/InputWithLabel";
import StatusDropdown from "@/components/ui/DropdownWithLabel/StatusDropdown";
import PriorityDropdown from "@/components/ui/DropdownWithLabel/PriorityDropdown";
import CategoryDropdown from "@/components/ui/DropdownWithLabel/CategoryDropdown";
import Button from "@/components/ui/Button";
import ColumnTitle from '@/components/ui/DataTable/ColumnTitle';
import { TimeFormatted } from '@/components/ui/Time/TimeFormatted';
import { Avatar } from '@/components/ui/Avatar/Avatar';
import {useGetCasesQuery} from "@/components/features/cases/services/cases.queries";
import {StatusName} from "@/types/status.types";
import {PriorityName} from "@/types/priority.types";
import {useQueryClient} from "@tanstack/react-query";
import {CASE_KEYS} from "@/components/features/cases/services/cases.queryKeys";

interface CaseRow {
    id: string;
    caseNumber: string;
    title: string;
    description: string;
    status: StatusName
    priority: PriorityName
    assignee?: {
        id: string;
        name: string;
        role: string;
    };
    creator: {
        id: string;
        name: string;
        role: string;
    };
    category: string;
    tickets: Array<{
        id: string;
        number: number;
    }>;
    createdAt: string;
    updatedAt: string;
}

const mapCaseToRow = (caseItem: Case): CaseRow => ({
    id: caseItem.case_id,
    caseNumber: caseItem.case_number_prefixed,
    title: caseItem.title,
    description: caseItem.description,
    status: caseItem.status.status_name,
    priority: caseItem.priority.priority_name,
    assignee: caseItem.assignee ? {
        id: caseItem.assignee.user_id,
        name: `${caseItem.assignee.first_name} ${caseItem.assignee.last_name}`,
        role: caseItem.assignee.role.role_name
    } : undefined,
    creator: {
        id: caseItem.creator.user_id,
        name: `${caseItem.creator.first_name} ${caseItem.creator.last_name}`,
        role: caseItem.creator.role.role_name
    },
    category: caseItem.category.category_name,
    tickets: caseItem.tickets.map(t => ({
        id: t.ticket.ticket_id,
        number: t.ticket.ticket_number
    })),
    createdAt: caseItem.created_at,
    updatedAt: caseItem.updated_at
});

const columns: ColumnDef<CaseRow>[] = [
    {
        id: 'number',
        header: ({ column }) => (
            <ColumnTitle title="Αριθμός" isSortable column={column} />
        ),
        accessorFn: (row) => row.caseNumber,
        cell: ({ row }) => (
            <div className="flex flex-col">
                <span className="font-medium text-gray-900">#{row.original.caseNumber}</span>
                <span className="text-sm text-gray-500 truncate max-w-md">
                    {row.original.title}
                </span>
            </div>
        ),
    },
    {
        id: 'title',
        header: ({ column }) => (
            <ColumnTitle title="Υπόθεση" isSortable column={column} />
        ),
        cell: ({ row }) => (
            <div className="flex flex-col">
                <div className="flex items-center space-x-2">
                    <FileText className="h-4 w-4 text-gray-400" />
                    <span className="font-medium text-gray-900">{row.original.title}</span>
                </div>
                <span className="text-sm text-gray-500 truncate max-w-md pl-6">
                    {row.original.description}
                </span>
            </div>
        ),
    },
    {
        id: 'status',
        header: ({ column }) => (
            <ColumnTitle title="Κατάσταση" isSortable column={column} />
        ),
        cell: ({ row }) => (
            <StatusBadge status={row.original.status} />
        ),
    },
    {
        id: 'priority',
        header: ({ column }) => (
            <ColumnTitle title="Προτεραιότητα" isSortable column={column} />
        ),
        cell: ({ row }) => (
            <PriorityBadge priority={row.original.priority} />
        ),
    },
    {
        id: 'assignee',
        header: ({ column }) => (
            <ColumnTitle title="Ανατέθηκε" isSortable column={column} />
        ),
        cell: ({ row }) => (
            row.original.assignee ? (
                <div className="flex items-center space-x-2">
                    <Avatar
                        firstName={row.original.assignee.name.split(' ')[0]}
                        lastName={row.original.assignee.name.split(' ')[1]}
                        roleName={row.original.assignee.role}
                        size="sm"
                    />
                    <span className="text-sm text-gray-900">{row.original.assignee.name}</span>
                </div>
            ) : (
                <span className="text-gray-400 italic">Μη ανατεθειμένο</span>
            )
        ),
    },
    {
        id: 'tickets',
        header: ({ column }) => (
            <ColumnTitle title="Συνδεδεμένα Αιτήματα" isSortable column={column} />
        ),
        cell: ({ row }) => (
            <div className="flex items-center space-x-1">
                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                    {row.original.tickets.length} αιτήματα
                </span>
            </div>
        ),
    },
    {
        id: 'category',
        header: ({ column }) => (
            <ColumnTitle title="Κατηγορία" isSortable column={column} />
        ),
        cell: ({ row }) => (
            <span className="text-sm text-gray-900">{row.original.category}</span>
        ),
    },
    {
        id: 'updatedAt',
        header: ({ column }) => (
            <ColumnTitle title="Τελευταία Ενημέρωση" isSortable column={column} />
        ),
        accessorFn: (row) => new Date(row.updatedAt).getTime().toString(),
        cell: ({ row }) => (
            <TimeFormatted date={row.original.updatedAt} />
        ),
    },
];

const FiltersPanel: React.FC<{
    filters: CaseFilters;
    onFiltersChange: (filters: CaseFilters) => void;
    onSearchChange: (search: string) => void;
    searchTerm: string;
    onClear: () => void;
}> = ({ filters, onFiltersChange, onSearchChange, searchTerm, onClear }) => {
    const handleFilterChange = (key: keyof CaseFilters, value: string) => {
        onFiltersChange({
            ...filters,
            [key]: value || undefined,
            page_number: 1
        });
    };

    return (
        <div className="bg-white p-4 rounded-lg shadow space-y-4">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                <InputWithLabel
                    label="Αναζήτηση"
                    placeholder="Αναζήτηση με τίτλο ή περιγραφή..."
                    value={searchTerm}
                    onChange={(e) => onSearchChange(e.target.value)}
                    icon={Search}
                />
                <StatusDropdown
                    value={filters.status_id ?? ''}
                    onChange={(value) => handleFilterChange('status_id', value)}
                    placeholder="Όλες οι καταστάσεις"
                />
                <PriorityDropdown
                    value={filters.priority_id ?? ''}
                    onChange={(value) => handleFilterChange('priority_id', value)}
                    placeholder="Όλες οι προτεραιότητες"
                />
                <CategoryDropdown
                    value={filters.category_id ?? ''}
                    onChange={(value) => handleFilterChange('category_id', value)}
                    placeholder="Όλες οι κατηγορίες"
                />
            </div>
            <div className="flex justify-end space-x-2">
                <Button
                    variant={ButtonVariant.OUTLINE}
                    onClick={onClear}
                >
                    Καθαρισμός
                </Button>
            </div>
        </div>
    );
};

const CaseList = () => {
    const [isFiltersOpen, setIsFiltersOpen] = React.useState(false);
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const [filters, setFilters] = React.useState<CaseFilters>({
        page_number: 1,
        page_size: 10
    });

    const [searchTerm, setSearchTerm] = React.useState('');
    const debouncedSearch = useDebounce(searchTerm, 500);

    // Fetch cases with filters
    const { data, isLoading, isError } = useGetCasesQuery({
        ...filters,
        search: debouncedSearch || undefined
    });

    const cases = data?.data ? data.data.map(mapCaseToRow) : [];

    const handleFiltersChange = (newFilters: CaseFilters) => {
        setFilters(newFilters);
    };

    const handleClearFilters = () => {
        setFilters({
            page_number: 1,
            page_size: filters.page_size
        });
        setSearchTerm('');
    };

    const getActiveFiltersCount = () => {
        return Object.entries(filters).filter(([key, value]) =>
            key !== 'page_number' &&
            key !== 'page_size' &&
            value !== undefined &&
            value !== ''
        ).length + (searchTerm ? 1 : 0);
    };

    const handleAddNew = () => {
        navigate(ROUTES.CASES.NEW);
    };

    const handleRefresh = async () => {
        await queryClient.refetchQueries({
            queryKey: CASE_KEYS.base,
            type: 'all'
        });
    };

    const handleRowClick = (row: CaseRow) => {
        navigate(ROUTES.CASES.DETAILS(row.id));
    };

    const handlePageChange = (pageIndex: number, pageSize: number) => {
        setFilters(prev => ({
            ...prev,
            page_number: pageIndex + 1,
            page_size: pageSize
        }));
    };

    if (isError) {
        return (
            <div className="h-full w-full flex items-center justify-center">
                <p className="text-error-500">Σφάλμα φόρτωσης δεδομένων</p>
            </div>
        );
    }

    return (
        <div className="h-full w-full mx-auto p-4 sm:p-6 lg:p-8">
            <PageTitle
                title="Υποθέσεις"
                description="Διαχείριση και παρακολούθηση όλων των υποθέσεων"
            />
            <PageToolbar
                primaryAction={{
                    label: 'Νέα Υπόθεση',
                    icon: Plus,
                    onClick: handleAddNew
                }}
                onRefreshClick={handleRefresh}
                onFilterClick={() => setIsFiltersOpen(!isFiltersOpen)}
                activeFiltersCount={getActiveFiltersCount()}
            />
            {isFiltersOpen && (
                <div className="mb-4">
                    <FiltersPanel
                        filters={filters}
                        onFiltersChange={handleFiltersChange}
                        searchTerm={searchTerm}
                        onSearchChange={setSearchTerm}
                        onClear={handleClearFilters}
                    />
                </div>
            )}
            <DataTable
                columns={columns}
                data={cases}
                enableGlobalFilter={false}
                enableColumnFilters={false}
                noDataMessage={
                    Object.keys(filters).length > 2 || searchTerm
                        ? "Δεν βρέθηκαν αποτελέσματα με τα επιλεγμένα φίλτρα"
                        : "Δεν υπάρχουν υποθέσεις προς εμφάνιση"
                }
                onRowClick={handleRowClick}
                isLoading={isLoading}
                serverPagination={data ? {
                    totalPages: data.total_pages,
                    totalResults: data.total_results,
                    onPageChange: handlePageChange
                } : undefined}
                initialState={{
                    pagination: {
                        pageSize: filters.page_size,
                        pageIndex: filters.page_number - 1
                    }
                }}
            />
        </div>
    );
};

export default CaseList;
