import {client} from '@/lib/httpClient';
import type {UpdateUserRequest, User, UserFilters} from '@/types/user.types';
import {ROUTES} from '@/constants/routes.constants';
import {createUserSchema, updateUserSchema} from '@/schemas/user.schemas';
import {ActionStatus, PaginatedResponse} from '@/types/api.types';

export const usersApi = {
    getAll: async (filters?: UserFilters) => {
        const response = await client.get<PaginatedResponse<User>>(
            ROUTES.USERS.API.BASE,
            {
                params: filters
            }
        );
        return response.data;
    },

    getById: async (id: string) => {
        const response = await client.get<User>(
            ROUTES.USERS.API.DETAIL(id)
        );
        return response.data;
    },

    create: async (data: UpdateUserRequest) : Promise<ActionStatus> => {
        const validated = createUserSchema.parse(data);
        const response = await client.post<ActionStatus>(
            ROUTES.USERS.API.BASE,
            validated
        );
        return response.data;
    },

    update: async (id: string, data: UpdateUserRequest) : Promise<ActionStatus> => {
        const validated = updateUserSchema.parse(data);
        const response = await client.put<ActionStatus>(
            ROUTES.USERS.API.DETAIL(id),
            validated
        );
        return response.data;
    },

    delete: async (id: string) => {
        const response = await client.delete<ActionStatus>(
            ROUTES.USERS.API.DETAIL(id)
        );
        return response.data;
    },

    activate: async (id: string) => {
        const response = await client.post<ActionStatus>(
            `${ROUTES.USERS.API.DETAIL(id)}/activate`
        );
        return response.data;
    },

    deactivate: async (id: string) => {
        const response = await client.post<ActionStatus>(
            `${ROUTES.USERS.API.DETAIL(id)}/deactivate`
        );
        return response.data;
    },

    sendHeartbeat: async () => {
        const response = await client.post<ActionStatus>(
            `${ROUTES.USERS.API.HEARTBEAT}`
        );
        return response.data;
    }
};
