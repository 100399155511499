// case.schemas.ts
import { z } from 'zod';
import type { UpdateCaseRequest, CaseFilters } from '@/types/case.types';

export const createCaseSchema = z.object({
    title: z.string()
        .min(1, 'Ο τίτλος είναι υποχρεωτικός')
        .max(200, 'Ο τίτλος δεν μπορεί να υπερβαίνει τους 200 χαρακτήρες')
        .trim(),
    description: z.string(),
    category_id: z.string().uuid('Μη έγκυρη κατηγορία'),
    priority_id: z.string().uuid('Μη έγκυρη προτεραιότητα'),
    assignee_id: z.string().uuid('Μη έγκυρος χρήστης'),
    tickets: z.array(z.string().uuid('Μη έγκυρο αίτημα'))  // Changed from ticket_ids to tickets
});

export const updateCaseSchema = createCaseSchema.partial().extend({
    status_id: z.string().uuid('Μη έγκυρη κατάσταση').optional(),
}) satisfies z.ZodType<UpdateCaseRequest>;

export const caseFiltersSchema = z.object({
    // These are required fields in the interface, so we don't make them optional
    page_number: z.number().positive('Ο αριθμός σελίδας πρέπει να είναι θετικός'),
    page_size: z.number().positive('Το μέγεθος σελίδας πρέπει να είναι θετικό'),
    // Optional filters
    category_id: z.string().uuid('Μη έγκυρη κατηγορία').optional(),
    priority_id: z.string().uuid('Μη έγκυρη προτεραιότητα').optional(),
    status_id: z.string().uuid('Μη έγκυρη κατάσταση').optional(),
    user_id: z.string().uuid('Μη έγκυρος χρήστης').optional(),
    search: z.string().optional(),
}) satisfies z.ZodType<CaseFilters>;
