// config/navigation.ts
import {administratorNavItems} from "@/routes/navItemsByRole/administratorNavItems";
import {helpdeskNavItems} from "@/routes/navItemsByRole/helpdeskNavItems";
import {clientEmployeeNavItems} from "@/routes/navItemsByRole/clientEmployeeNavItems";
import {clientManagerNavItems} from "@/routes/navItemsByRole/clientManagerNavItems";
import {clientAdministratorNavItems} from "@/routes/navItemsByRole/clientAdministratorNavItems";
import {agentNavItems} from "@/routes/navItemsByRole/agentNavItems";
import {managerNavItems} from "@/routes/navItemsByRole/managerNavItems";
import {techNavItems} from "@/routes/navItemsByRole/techNavItems";
import { LucideIcon } from 'lucide-react';

// Base types
export interface NavChild {
    name: string;
    href: string;
    requiredPermission?: string;
}

export interface NavItem {
    name: string;
    icon: LucideIcon;  // Use LucideIcon type from lucide-react
    href?: string;
    requiredPermission?: string;
    children?: NavChild[];
}

// Administrator Navigation
export const administratorNavigation: NavItem[] = administratorNavItems

// Help Desk Navigation
export const helpdeskNavigation: NavItem[] = helpdeskNavItems

// Manager Navigation
export const managerNavigation: NavItem[] = managerNavItems

// Agent Navigation
export const agentNavigation: NavItem[] = agentNavItems

// Tech Navigation
export const techNavigation: NavItem[] = techNavItems

// Client Administrator Navigation
export const clientAdministratorNavigation: NavItem[] = clientAdministratorNavItems

// Client Manager Navigation
export const clientManagerNavigation: NavItem[] = clientManagerNavItems

// Client Employee Navigation
export const clientEmployeeNavigation: NavItem[] = clientEmployeeNavItems

// Helper function to get navigation based on roles
export const getNavigationByRoles = (roles: string[]): NavItem[] => {
    if (roles.includes('administrator')) {
        return administratorNavigation;
    }
    if (roles.includes('helpdesk')) {
        return helpdeskNavigation;
    }
    if (roles.includes('manager')) {
        return managerNavigation;
    }
    if (roles.includes('agent')) {
        return agentNavigation;
    }
    if (roles.includes('tech')) {
        return techNavigation;
    }
    if (roles.includes('client_administrator')) {
        return clientAdministratorNavigation;
    }
    if (roles.includes('client_manager')) {
        return clientManagerNavigation;
    }
    if (roles.includes('client_employee')) {
        return clientEmployeeNavigation;
    }

    // Default to most restricted navigation
    return clientEmployeeNavigation;
};
