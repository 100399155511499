// src/features/tickets/services/tickets.mutations.ts
import { toast } from 'react-toastify';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ticketsApi } from "./tickets.api";
import { TICKET_KEYS } from "./tickets.queryKeys";
import type {CreateTicketRequest, UpdateTicketPayload} from "@/types/ticket.types";

export function useCreateTicketMutation() {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (data: CreateTicketRequest) => ticketsApi.create(data),
        onSuccess: async (response) => {
            await queryClient.invalidateQueries({
                queryKey: [TICKET_KEYS.base]
            });
            toast.success('Το αίτημα δημιουργήθηκε επιτυχώς');
            return response;
        },
        onError: (error) => {
            console.error('Create ticket error:', error);
            toast.error('Σφάλμα κατά τη δημιουργία του αιτήματος');
        }
    });
}

export function useUpdateTicketStatusMutation(ticketId: string) {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (data: { status_id: string }) =>
            ticketsApi.updateStatus(ticketId, data),
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: [TICKET_KEYS.base]
            });
            toast.success('Η κατάσταση του ticket ενημερώθηκε επιτυχώς');
        },
        onError: (error) => {
            console.error('Update status error:', error);
            toast.error('Σφάλμα κατά την ενημέρωση της κατάστασης');
        }
    });
}

export function useUpdateTicketAssigneeMutation(ticketId: string) {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (data: { assignee_id: string }) =>
            ticketsApi.updateAssignee(ticketId, data),
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: [TICKET_KEYS.base]
            });
            toast.success('Η ανάθεση του ticket ενημερώθηκε επιτυχώς');
        },
        onError: (error) => {
            console.error('Update assignee error:', error);
            toast.error('Σφάλμα κατά την ανάθεση');
        }
    });
}

export function useDeleteTicketMutation() {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (id: string) => ticketsApi.delete(id),
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: [TICKET_KEYS.base]
            });
            toast.success('Το αίτημα διαγράφηκε επιτυχώς');
        },
        onError: (error) => {
            console.error('Delete ticket error:', error);
            toast.error('Σφάλμα κατά τη διαγραφή του αιτήματος');
        }
    });
}

export function useUpdateTicketMutation(ticketId: string) {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (data: UpdateTicketPayload) =>
            ticketsApi.update(ticketId, data),
        onSuccess: async (response) => {
            if (response.action_status) {
                await queryClient.invalidateQueries({
                    queryKey: [TICKET_KEYS.base]
                });
                toast.success('Το ticket ενημερώθηκε επιτυχώς');
            } else {
                toast.error(response.action_message || 'Σφάλμα κατά την ενημέρωση');
            }
        },
        onError: (error) => {
            console.error('Update ticket error:', error);
            toast.error('Σφάλμα κατά την ενημέρωση του ticket');
        }
    });
}

export function useUpdateTicketRatingMutation(ticketId: string) {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (data: { rating_1: number; rating_2: number; rating_3: number }) =>
            ticketsApi.updateRatings(ticketId, data),
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: [TICKET_KEYS.base]
            });
            toast.success('Η αξιολόγηση καταχωρήθηκε επιτυχώς');
        },
        onError: (error) => {
            console.error('Update rating error:', error);
            toast.error('Σφάλμα κατά την καταχώρηση της αξιολόγησης');
        }
    });
}
