import {Table} from "@tanstack/react-table";
import {useMemo} from "react";
import {ChevronLeft, ChevronRight} from "lucide-react";

interface ServerPaginationProps<TData> {
    table: Table<TData>
    totalPages: number
    totalResults: number
    onPageChange: (pageIndex: number, pageSize: number) => void
}


export function ServerPagination<TData>({
                                     table,
                                     totalPages,
                                     totalResults,
                                     onPageChange,
                                 }: ServerPaginationProps<TData>) {
    const currentPage = table.getState().pagination.pageIndex + 1
    const pageSize = table.getState().pagination.pageSize
    const maxPagesToShow = 7

    const pagesToShow = useMemo(() => {
        if (totalPages <= maxPagesToShow) {
            return Array.from({ length: totalPages }, (_, index) => index + 1)
        }

        const startPage = Math.max(currentPage - 2, 1)
        const endPage = Math.min(currentPage + 2, totalPages)
        let pages: (number | string)[]

        pages = Array.from(
            { length: endPage - startPage + 1 },
            (_, i) => startPage + i,
        )

        if (startPage > 2) pages = [1, '...', ...pages]
        if (endPage < totalPages - 1) pages = [...pages, '...', totalPages]

        return pages
    }, [currentPage, totalPages])

    const handlePageChange = (newPage: number) => {
        table.setPageIndex(newPage - 1)
        onPageChange(newPage - 1, pageSize)
    }

    const handlePageSizeChange = (newPageSize: number) => {
        table.setPageSize(newPageSize)
        onPageChange(0, newPageSize) // Reset to first page when changing page size
    }

    return (
        <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
            <div className="sm:flex sm:flex-1 sm:items-center sm:justify-between">
                <div className="flex items-center gap-2">
                    <p className="text-sm text-gray-700">
                        Εμφάνιση{' '}
                        <span className="font-medium">
                {Math.min((currentPage - 1) * pageSize + 1, totalResults)}
            </span>{' '}
                        έως{' '}
                        <span className="font-medium">
                {Math.min(currentPage * pageSize, totalResults)}
            </span>{' '}
                        από{' '}
                        <span className="font-medium">{totalResults}</span>{' '}
                        αποτελέσματα
                    </p>
                    <select
                        value={pageSize}
                        onChange={(e) => handlePageSizeChange(Number(e.target.value))}
                        className="rounded-md border-gray-300 py-1 pl-2 pr-8 text-sm focus:border-primary-500 focus:ring-primary-500"
                    >
                        {[10, 20, 50, 100].map((size) => (
                            <option key={size} value={size}>
                                {size} ανά σελίδα
                            </option>
                        ))}
                    </select>
                </div>

                <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm">
                    <button
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                        className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 disabled:opacity-50"
                    >
                        <span className="sr-only">Προηγούμενη</span>
                        <ChevronLeft className="h-5 w-5"/>
                    </button>

                    {pagesToShow.map((page, index) => {
                        if (page === '...') {
                            return (
                                <span
                                    key={`ellipsis-${index}`}
                                    className="relative inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 ring-1 ring-inset ring-gray-300"
                                >
                        ...
                    </span>
                            )
                        }

                        const isCurrentPage = currentPage === page
                        return (
                            <button
                                key={`page-${page}`}
                                onClick={() => handlePageChange(page as number)}
                                className={`
                        relative inline-flex items-center px-4 py-2 text-sm font-medium 
                        ${isCurrentPage
                                    ? 'z-10 bg-primary-600 text-white focus:z-20'
                                    : 'text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50'
                                }
                    `}
                            >
                                {page}
                            </button>
                        )
                    })}

                    <button
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                        className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 disabled:opacity-50"
                    >
                        <span className="sr-only">Επόμενη</span>
                        <ChevronRight className="h-5 w-5"/>
                    </button>
                </nav>
            </div>
        </div>
    )
}
