// DashboardLayout.tsx
import { Outlet } from 'react-router-dom';
import { cn } from "@/lib/utils";
import {Sidebar} from "@/components/layouts/Sidebar/Sidebar";
import Header from "@/components/layouts/Header/Header";
import UserActivityHeartbeat from "@/components/features/users/components/UserActivityHeartbeat";

interface DashboardLayoutProps {
    className?: string;
}

export default function DashboardLayout({ className }: DashboardLayoutProps) {
    return (
        <div className={cn('flex h-screen bg-gray-100', className)}>
            <UserActivityHeartbeat />
            <Sidebar />
            <div className={cn(
                "flex-1",
                "transition-all duration-300 ease-in-out overflow-auto",
            )}>
                <Header />
                <main className="h-[calc(100%-4rem)]">
                    <div className="h-full w-full bg-gray-100">
                        <Outlet />
                    </div>
                </main>
            </div>
        </div>
    );
}
