export const calculateSLARemaining = (createdAt: string, resolutionTime: number) => {
    const created = new Date(createdAt);
    const deadline = new Date(created.getTime() + resolutionTime * 60 * 60 * 1000); // Convert hours to milliseconds
    const now = new Date();

    // If deadline has passed
    if (now > deadline) {
        return 'Breached';
    }

    // Calculate remaining time
    const remainingMs = deadline.getTime() - now.getTime();
    const remainingHours = Math.ceil(remainingMs / (1000 * 60 * 60));

    return `${remainingHours} ώρες`;
};
