// TicketListCard.tsx
import {TimeFormatted} from "@/components/ui/Time/TimeFormatted";
import {Building2, Clock} from "lucide-react";
import PriorityBadge from "@/components/ui/Badge/PriorityBadge";
import StatusBadge from "@/components/ui/Badge/StatusBadge";
import {Ticket} from "@/types/ticket.types";
import {Card, CardContent, CardHeader, CardTitle} from "@/components/ui/Card";
import {Badge} from "@/components/ui/Badge";
import Button from "@/components/ui/Button";
import {ButtonSize, ButtonVariant} from "@/components/ui/Button/ButtonSize";
import {calculateSLARemaining} from "@/utils/sla.utils";

interface TicketListCardProps {
    title: string;
    tickets: Ticket[];
    badgeCount?: number;
    badgeText?: string;
    badgeVariant?: 'default' | 'warning' | 'error' | 'success' | 'outline';
    showViewAll?: boolean;
    onViewAll?: () => void;
    onTicketAction?: (ticketId: string) => void;
    actionButtonText?: string;
    showSLA?: boolean;
}

export const TicketListCard = ({
                                   title,
                                   tickets,
                                   badgeCount,
                                   badgeText,
                                   badgeVariant = 'default',
                                   showViewAll = true,
                                   onViewAll,
                                   onTicketAction,
                                   actionButtonText = 'Προβολή',
                                   showSLA = false,
                               }: TicketListCardProps) => {
    return (
        <Card>
            <CardHeader>
                <div className="flex items-center justify-between">
                    <CardTitle className="flex items-center">
                        <span>{title}</span>
                        {badgeCount !== undefined && (
                            <Badge variant={badgeVariant} className="ml-2">
                                {badgeCount} {badgeText}
                            </Badge>
                        )}
                    </CardTitle>
                    {showViewAll && (
                        <Button
                            variant={ButtonVariant.OUTLINE}
                            size={ButtonSize.SM}
                            onClick={onViewAll}
                        >
                            Προβολή Όλων
                        </Button>
                    )}
                </div>
            </CardHeader>
            <CardContent>
                <div className="space-y-4">
                    {tickets.map(ticket => {
                        const slaRemaining = calculateSLARemaining(
                            ticket.created_at,
                            ticket.priority.resolution_time
                        );
                        const isBreached = slaRemaining === 'Breached';

                        return (
                            <div key={ticket.ticket_id} className="flex items-start justify-between border-b pb-4">
                                <div className="space-y-1">
                                    <div className="flex items-center space-x-2">
                                        <span className="font-medium">#{ticket.ticket_number}</span>
                                        {ticket.status && (
                                            <StatusBadge status={ticket.status.status_name} />
                                        )}
                                        {ticket.priority && (
                                            <PriorityBadge priority={ticket.priority.priority_name} />
                                        )}
                                    </div>
                                    <p className="text-sm text-gray-900">{ticket.title}</p>
                                    <div className="flex items-center space-x-2 text-sm text-gray-500">
                                        <Building2 className="h-4 w-4" />
                                        <span>{ticket.company.company_name}</span>
                                        {(ticket.department?.department_name || ticket.assignee) && (
                                            <>
                                                <span>•</span>
                                                <span>
                                                    {ticket.department?.department_name ||
                                                        `${ticket.assignee?.first_name} ${ticket.assignee?.last_name}`}
                                                </span>
                                            </>
                                        )}
                                    </div>
                                </div>
                                <div className="flex flex-col items-end space-y-2">
                                    <TimeFormatted date={ticket.created_at} className="text-sm text-gray-500" />
                                    {showSLA && (
                                        <div className={`flex items-center text-sm ${isBreached ? 'text-error-600' : 'text-gray-600'}`}>
                                            <Clock className="h-4 w-4 mr-1" />
                                            <span>SLA: {slaRemaining}</span>
                                        </div>
                                    )}
                                    <Button
                                        variant={ButtonVariant.OUTLINE}
                                        size={ButtonSize.SM}
                                        onClick={() => onTicketAction?.(ticket.ticket_id)}
                                    >
                                        {actionButtonText}
                                    </Button>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </CardContent>
        </Card>
    );
};
