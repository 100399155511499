// CombinedTicketTimeline.tsx
import React from 'react';
import { MessageSquare, FileText, Check, Clock, RefreshCw } from 'lucide-react';
import { TimeFormatted } from '@/components/ui/Time/TimeFormatted';
import { CaseTicket } from '@/types/case.types';

interface TimelineEvent {
    date: string;
    type: 'message' | 'status' | 'created' | 'closed' | 'responded';
    ticket: {
        id: string;
        number: number;
        title: string;
    };
    content: string;
}

interface CombinedTicketTimelineProps {
    tickets: CaseTicket[];
}

export const CombinedTicketTimeline: React.FC<CombinedTicketTimelineProps> = ({ tickets }) => {

    const events = React.useMemo(() => {
        const allEvents: TimelineEvent[] = [];

        tickets.forEach(({ ticket }) => {
            // Creation event
            allEvents.push({
                date: ticket.created_at,
                type: 'created',
                ticket: {
                    id: ticket.ticket_id,
                    number: ticket.ticket_number,
                    title: ticket.title
                },
                content: 'Δημιουργία αιτήματος'
            });

            // Response event
            if (ticket.responded_at) {
                allEvents.push({
                    date: ticket.responded_at,
                    type: 'responded',
                    ticket: {
                        id: ticket.ticket_id,
                        number: ticket.ticket_number,
                        title: ticket.title
                    },
                    content: 'Απάντηση στο αίτημα'
                });
            }

            // Closure event
            if (ticket.closed_at) {
                allEvents.push({
                    date: ticket.closed_at,
                    type: 'closed',
                    ticket: {
                        id: ticket.ticket_id,
                        number: ticket.ticket_number,
                        title: ticket.title
                    },
                    content: 'Κλείσιμο αιτήματος'
                });
            }
        });

        return allEvents.sort((a, b) =>
            new Date(b.date).getTime() - new Date(a.date).getTime()
        );
    }, [tickets]);

    if (events.length === 0) {
        return (
            <div className="flex flex-col items-center justify-center py-8 text-gray-500">
                <Clock className="h-8 w-8 mb-2 text-gray-400" />
                <p>Δεν υπάρχει ιστορικό ενεργειών</p>
            </div>
        );
    }

    const getIconStyles = (type: string) => {
        switch (type) {
            case 'created':
                return {
                    icon: <FileText className="h-5 w-5" />,
                    bg: 'bg-blue-50',
                    iconColor: 'text-blue-500',
                    ring: 'ring-blue-50'
                };
            case 'responded':
                return {
                    icon: <MessageSquare className="h-5 w-5" />,
                    bg: 'bg-green-50',
                    iconColor: 'text-green-500',
                    ring: 'ring-green-50'
                };
            case 'closed':
                return {
                    icon: <Check className="h-5 w-5" />,
                    bg: 'bg-purple-50',
                    iconColor: 'text-purple-500',
                    ring: 'ring-purple-50'
                };
            default:
                return {
                    icon: <RefreshCw className="h-5 w-5" />,
                    bg: 'bg-gray-50',
                    iconColor: 'text-gray-500',
                    ring: 'ring-gray-50'
                };
        }
    };

    return (
        <div className="flow-root">
            <ul className="-mb-8">
                {events.map((event, eventIdx) => {
                    const styles = getIconStyles(event.type);
                    return (
                        <li key={event.date + eventIdx}>
                            <div className="relative pb-8">
                                {eventIdx !== events.length - 1 && (
                                    <span
                                        className="absolute left-5 top-5 -ml-px h-full w-0.5 bg-gray-200"
                                        aria-hidden="true"
                                    />
                                )}
                                <div className="relative flex items-start space-x-3">
                                    {/* Icon container - fixed width and alignment */}
                                    <div className="flex-shrink-0 relative">
                                        <div
                                            className={`flex h-8 w-8 items-center justify-center rounded-full ${styles.bg} ring-1 ${styles.ring}`}>
                                            <div className={styles.iconColor}>
                                                {styles.icon}
                                            </div>
                                        </div>
                                    </div>

                                    {/* Content container - consistent width and spacing */}
                                    <div className="flex-1 min-w-0">
                                        <div className="flex items-center justify-between space-x-4">
                                            <div className="flex-1">
                                                <h3 className="text-sm font-medium text-gray-900">
                                                    #{event.ticket.number} - {event.content}
                                                </h3>
                                                <p className="mt-0.5 text-sm text-gray-500">{event.ticket.title}</p>
                                            </div>
                                            <div className="flex-shrink-0 whitespace-nowrap text-sm text-gray-500">
                                                <TimeFormatted date={event.date}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    );
                    }
                )}
            </ul>
        </div>
    );
}
