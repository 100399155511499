import React from 'react';
import SelectMenu from '@/components/ui/SelectMenu';
import { useGetUsersQuery } from '@/components/features/users/services/users.queries';
import {User, UserFilters} from '@/types/user.types';

interface UserDropdownProps {
    label?: string;
    value?: string;
    onChange?: (userId: string) => void;
    error?: string;
    required?: boolean;
    disabled?: boolean;
    placeholder?: string;
    company_id?: string;
    filterBlocked?: boolean;
}

const UserDropdown = React.forwardRef<HTMLButtonElement, UserDropdownProps>(
    ({
         label = 'Αναφορά Αιτήματος από',
         value,
         onChange,
         error,
         required = false,
         disabled = false,
         placeholder = 'Επιλέξτε υπεύθυνο',
         company_id,
         filterBlocked = true,
         ...props
     }, ref) => {
        console.log('company_id ', company_id);
        const filters: UserFilters | undefined = company_id
            ? {
                page_number: 1,
                page_size: 200,
                company_id,
                blocked: filterBlocked
            }
            : undefined;

        console.log('filters ', filters);

        const { data: users, isLoading, isError } = useGetUsersQuery(filters);

        console.log('users ', users);

        const handleChange = (selected: { id: string; label: string }) => {
            onChange?.(selected.id);
        };

        const userOptions = React.useMemo(() => {
            if (!users) return [];

            return users.data.map((user: User) => ({
                id: user.user_id,
                label: `${user.first_name} ${user.last_name}`,
                email: user.email_address, // Updated to match API response
                role: user.role,
                department: user.department?.department_name // Updated to match API response
            }));
        }, [users]);

        return (
            <SelectMenu
                ref={ref}
                label={label}
                menuItems={userOptions}
                onChange={handleChange}
                defaultValue={value}
                error={isError ? 'Σφάλμα φόρτωσης χρηστών' : error}
                disabled={disabled || isLoading}
                required={required}
                placeholder={isLoading ? 'Φόρτωση...' : placeholder}
                searchPlaceholder="Αναζήτηση χρήστη..."
                {...props}
            />
        );
    }
);

UserDropdown.displayName = 'UserDropdown';

export default UserDropdown;
