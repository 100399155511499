// src/features/notifications/services/notifications.api.ts

import {client} from '@/lib/httpClient';
import type {NotificationFilters, NotificationsListResponse,} from '@/types/notification.types';
import {ActionStatus} from "@/types/api.types";

export const notificationsApi = {
    getUserNotifications: async (filters?: NotificationFilters) => {
        const response = await client.get<NotificationsListResponse>(
            '/api/v1/notifications',
            {params: filters}
        );
        return response.data;
    },

    updateNotificationStatus: async ({id, isSeen}: { id: string, isSeen: boolean }) : Promise<ActionStatus> => {
        const response = await client.put<ActionStatus>(
            `/api/v1/notifications/${id}`,
            {is_seen: isSeen}
        );
        return response.data;
    }
};