// cases.api.ts
import { client } from '@/lib/httpClient';
import type {
    Case,
    CaseResponse,
    CreateCaseRequest,
    CaseFilters,
    CasesListResponse,
    UpdateCaseRequest
} from "@/types/case.types";
import { createCaseSchema, updateCaseSchema } from '@/schemas/case.schemas';
import { ROUTES } from "@/constants/routes.constants";

export const casesApi = {
    getAll: async (filters?: CaseFilters) => {
        const response = await client.get<CasesListResponse>(
            ROUTES.CASES.API.BASE,
            {
                params: {
                    page_number: filters?.page_number || 1,
                    page_size: filters?.page_size || 10,
                    category_id: filters?.category_id,
                    priority_id: filters?.priority_id,
                    status_id: filters?.status_id,
                    user_id: filters?.user_id
                }
            }
        );
        return response.data;
    },

    getById: async (id: string) => {
        const response = await client.get<Case>(
            ROUTES.CASES.API.DETAIL(id)
        );
        return response.data;
    },

    create: async (data: CreateCaseRequest) => {
        const validated = createCaseSchema.parse(data);
        const response = await client.post<CaseResponse>(
            ROUTES.CASES.API.BASE,
            {
                title: validated.title,
                description: validated.description,
                category_id: validated.category_id,
                priority_id: validated.priority_id,
                assignee_id: validated.assignee_id,
                tickets: validated.tickets
            }
        );
        return response.data;
    },

    update: async (id: string, data: UpdateCaseRequest) => {
        const validated = updateCaseSchema.parse(data);
        const response = await client.put<CaseResponse>(
            ROUTES.CASES.API.DETAIL(id),
            validated
        );
        return response.data;
    },

    updateStatus: async (id: string, statusId: string) => {
        const response = await client.put<CaseResponse>(
            ROUTES.CASES.API.STATUS(id, statusId),
            { status_id: statusId }
        );
        return response.data;
    },

    updateAssignee: async (id: string, userId: string) => {
        const response = await client.put<CaseResponse>(
            ROUTES.CASES.API.ASSIGN(id, userId),
            { assignee_id: userId }
        );
        return response.data;
    },

    duplicateTicket: async (caseId: string, data: { ticket_id: string; assignees: string[] }) => {
        const response = await client.post<CaseResponse>(
            ROUTES.CASES.API.DUPLICATE_TICKET(caseId),
            data
        );
        return response.data;
    }
};
