import React from 'react';

interface TimeAgoProps {
    date: string | Date;
    className?: string;
    shortFormat?: boolean;
}

const GREEK_MONTHS_SHORT = [
    'Ιαν', 'Φεβ', 'Μαρ', 'Απρ', 'Μαϊ', 'Ιουν',
    'Ιουλ', 'Αυγ', 'Σεπ', 'Οκτ', 'Νοε', 'Δεκ'
];

const parseDate = (date: string | Date): Date => {
    if (date instanceof Date) return date;
    const dateString = date.endsWith('Z') ? date : `${date}Z`;
    return new Date(dateString);
};

const formatDateTime = (date: Date): string => {
    const day = date.getUTCDate().toString().padStart(2, '0');
    const month = GREEK_MONTHS_SHORT[date.getUTCMonth()];
    const year = date.getUTCFullYear();
    const hours = date.getUTCHours().toString().padStart(2, '0');
    const minutes = date.getUTCMinutes().toString().padStart(2, '0');

    return `${day} ${month} ${year} - ${hours}:${minutes}`;
};

const formatFullDateTime = (date: Date): string => {
    return date.toLocaleString('el-GR', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
        timeZone: 'UTC'
    });
};

export const TimeFormatted: React.FC<TimeAgoProps> = ({ date, className }) => {
    try {
        const parsedDate = parseDate(date);
        if (isNaN(parsedDate.getTime())) {
            return <time className={className}>μη έγκυρη ημερομηνία</time>;
        }

        const formattedDate = formatDateTime(parsedDate);
        const fullDateTime = formatFullDateTime(parsedDate);

        return (
            <time
                className={className}
                title={fullDateTime}
                dateTime={parsedDate.toISOString()}
                aria-label={`Δημοσιεύτηκε ${fullDateTime}`}
            >
                {formattedDate}
            </time>
        );
    } catch (error) {
        console.error('Error formatting date:', error);
        return <time className={className}>μη έγκυρη ημερομηνία</time>;
    }
};
