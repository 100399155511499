import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    Briefcase,
    Building2,
    Calendar,
    Clock,
    Eye,
    EyeOff, MessageCircle,
    MessageSquare,
    NotepadText, Paperclip, PenLine,
    Tag,
    UserCog,
} from 'lucide-react';

import { useGetTicketQuery } from "@/components/features/tickets/services/tickets.queries";
import { useGetTicketMessagesQuery } from "@/components/features/messages/services/messages.queries";
import { useCreateMessageMutation } from "@/components/features/messages/services/messages.mutations";
import { useCreateNoteMutation } from "@/components/features/notes/services/notes.mutations";
import { messagesApi } from "@/components/features/messages/services/messages.api";

import { PageHeader } from "@/components/layouts/PageLayout/PageHeader";
import StatusBadge from "@/components/ui/Badge/StatusBadge";
import PriorityBadge from "@/components/ui/Badge/PriorityBadge";
import Button from "@/components/ui/Button";
import { Avatar } from "@/components/ui/Avatar/Avatar";
import { TimeFormatted } from "@/components/ui/Time/TimeFormatted";
import { RoleBadge } from "@/components/ui/Badge";
import { TicketDialogs } from "@/components/features/tickets/components/TicketDialogs";

import {mapStatusToEnum, StatusEnum} from "@/types/status.types";
import { mapPriorityToEnum } from "@/types/priority.types";
import { ButtonSize, ButtonVariant } from "@/components/ui/Button/ButtonSize";
import useUserStore from "@/stores/useUserStore";
import { isClient } from "@/utils/auth.utils";
import AttachmentList from "@/components/ui/AttachmentList/AttachmentList";
import {AttachmentPreview, FileUpload} from "@/components/ui/FileUpload/FileUpload";
import { Message as MessageType } from "@/types/message.types";
import { Note as NoteType } from "@/types/note.types";
import {TicketTimeline} from "@/components/features/tickets/components/TicketTimeline";
import {SLAStatusDisplay} from "@/components/features/tickets/components/SLAStatusDisplay";
import {toast} from "react-toastify";
import {notesApi} from "@/components/features/notes/services/notes.api";
import EditTicketDialog from "@/components/features/tickets/components/EditTicketDialog";
import TicketProductsCard from "@/pages/Tickets/TicketProductsCard";
import {RatingCard} from "@/components/ui/RatingCard/RatingCard";
import {useUpdateTicketRatingMutation} from "@/components/features/tickets/services/tickets.mutations";
import {useQuery} from "@tanstack/react-query";

interface MessageProps {
    message: MessageType;
    onAttachmentClick: (id: string) => void;
    isInternal: boolean;
}

interface NoteProps {
    note: NoteType;
    onAttachmentClick: (id: string) => void;
}

// Message Component
const Message: React.FC<MessageProps> = ({ message, onAttachmentClick, isInternal }) => {
    const [showAttachments, setShowAttachments] = useState(false);

    const attachmentCount = message.attachments.length;
    const attachmentText = attachmentCount === 1 ? 'συνημμένο' : 'συνημμένα';

    return (
        <div className={`flex space-x-3 sm:space-x-4 ${
            isInternal ? 'bg-amber-50 p-4 rounded-lg border border-amber-200' : ''
        }`}>
            <div className="flex-shrink-0">
                <Avatar
                    firstName={message.sender.first_name}
                    lastName={message.sender.last_name}
                    roleName={message.sender.role.role_name}
                    className="h-10 w-10"
                />
            </div>
            <div className="flex-1 min-w-0">
                <div className="flex items-center justify-between">
                    <div className="flex items-center space-x-2">
                        <p className="text-sm font-medium text-gray-900">
                            {`${message.sender.first_name} ${message.sender.last_name}`}
                        </p>
                        <RoleBadge role={message.sender.role}/>
                        {isInternal && (
                            <span className="inline-flex items-center rounded-full px-2 py-0.5 text-xs font-medium bg-amber-100 text-amber-700">
                                <EyeOff className="w-3 h-3 mr-1"/>
                                Εσωτερικό
                            </span>
                        )}
                    </div>
                    <TimeFormatted date={message.created_at} className="text-sm text-gray-500"/>
                </div>
                <p className="mt-1 text-sm text-gray-600">{message.message_content}</p>
                {attachmentCount > 0 && (
                    <div className="mt-2">
                        <button
                            onClick={() => setShowAttachments(!showAttachments)}
                            className="inline-flex items-center text-sm text-gray-500 hover:text-gray-700"
                        >
                            <Paperclip className="w-4 h-4 mr-1" />
                            {attachmentCount} {attachmentText}
                        </button>
                        {showAttachments && (
                            <AttachmentList
                                attachments={message.attachments}
                                onAttachmentClick={onAttachmentClick}
                                className="mt-2"
                            />
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

// Note Component
const Note: React.FC<NoteProps> = ({ note, onAttachmentClick }) => {
    const [showAttachments, setShowAttachments] = useState(false);

    const attachmentCount = note.attachments?.length || 0;
    const attachmentText = attachmentCount === 1 ? 'συνημμένο' : 'συνημμένα';

    return (
        <div className="border-b border-amber-200 pb-4 bg-amber-50 p-4 rounded-lg mb-4">
            <div className="flex items-start space-x-3">
                <Avatar
                    firstName={note.creator.first_name}
                    lastName={note.creator.last_name}
                    roleName={note.creator.role.role_name}
                    className="h-10 w-10"
                />
                <div className="flex-1">
                    <div className="flex items-center justify-between">
                        <div className="flex items-center space-x-2">
                            <p className="text-sm font-medium text-amber-900">
                                {`${note.creator.first_name} ${note.creator.last_name}`}
                            </p>
                            <RoleBadge role={note.creator.role}/>
                            <span className="inline-flex items-center rounded-full px-2 py-0.5 text-xs font-medium bg-amber-100 text-amber-700">
                                <EyeOff className="w-3 h-3 mr-1"/>
                                Εσωτερικό
                            </span>
                        </div>
                        <TimeFormatted date={note.created_at} className="text-sm text-amber-700"/>
                    </div>
                    <p className="mt-1 text-sm text-amber-800">{note.note_content}</p>
                    {attachmentCount > 0 && (
                        <div className="mt-2">
                            <button
                                onClick={() => setShowAttachments(!showAttachments)}
                                className="inline-flex items-center text-sm text-amber-700 hover:text-amber-900"
                            >
                                <Paperclip className="w-4 h-4 mr-1" />
                                {attachmentCount} {attachmentText}
                            </button>
                            {showAttachments && (
                                <AttachmentList
                                    attachments={note.attachments}
                                    onAttachmentClick={onAttachmentClick}
                                    className="mt-2"
                                />
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

interface ComposerProps {
    content: string;
    onContentChange: (content: string) => void;
    onSend: () => void;
    files: File[];
    onFilesChange: (files: File[]) => void;
    isInternal?: boolean;
    onInternalChange?: (isInternal: boolean) => void;
    placeholder?: string;
    sendButtonText?: string;
    title: string;
    showInternalToggle?: boolean;
    showFileUpload: boolean;
    onShowFileUploadChange: (show: boolean) => void;
}

// MessageComposer Component - Updated styling for internal content
const MessageComposer: React.FC<ComposerProps> = ({
                                                      content,
                                                      onContentChange,
                                                      onSend,
                                                      files,
                                                      onFilesChange,
                                                      isInternal,
                                                      onInternalChange,
                                                      placeholder,
                                                      sendButtonText = "Αποστολή",
                                                      title,
                                                      showInternalToggle = false,
                                                      showFileUpload,
                                                      onShowFileUploadChange,
                                                  }) => {
    const [showError, setShowError] = useState(false);

    const handleRemoveFile = (index: number) => {
        const updatedFiles = files.filter((_, i) => i !== index);
        onFilesChange(updatedFiles);
    };

    const handleSend = () => {
        if (!content.trim()) {
            setShowError(true);
            return;
        }
        setShowError(false);
        onSend();
    };

    const handleContentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (showError) {
            setShowError(false);
        }
        onContentChange(e.target.value);
    };

    const internalStyles = isInternal ? {
        container: 'bg-amber-50 border-amber-200',
        textarea: 'bg-amber-50 border-amber-200 text-amber-900 placeholder-amber-700',
        button: 'text-amber-700 hover:text-amber-900',
        title: 'text-amber-900'
    } : {
        container: 'border-gray-200',
        textarea: 'bg-white border-gray-200 text-gray-900 placeholder-gray-500',
        button: 'text-gray-500 hover:text-gray-700',
        title: 'text-gray-900'
    };

    return (
        <div className={`border rounded-lg p-4 ${internalStyles.container}`}>
            <div className="relative">
                {/* Top Bar */}
                <div className="flex items-center justify-between mb-2">
                    <h3 className={`text-lg font-medium ${internalStyles.title}`}>{title}</h3>
                    <div className="flex items-center gap-2">
                        {showInternalToggle && onInternalChange && (
                            <Button
                                variant={ButtonVariant.OUTLINE}
                                size={ButtonSize.SM}
                                onClick={() => onInternalChange(!isInternal)}
                                title={isInternal ? "Αλλαγή σε δημόσιο" : "Αλλαγή σε εσωτερικό"}
                                className={isInternal ? "border-amber-200 text-amber-700 hover:bg-amber-100" : ""}
                            >
                                {isInternal ? (
                                    <>
                                        <EyeOff className="w-4 h-4 mr-2" />
                                        Εσωτερικό Μήνυμα
                                    </>
                                ) : (
                                    <>
                                        <Eye className="w-4 h-4 mr-2" />
                                        Δημόσιο Μήνυμα
                                    </>
                                )}
                            </Button>
                        )}
                        <Button
                            variant={ButtonVariant.GHOST}
                            size={ButtonSize.SM}
                            onClick={() => onShowFileUploadChange(!showFileUpload)}
                            className={internalStyles.button}
                            title="Προσθήκη Συνημμένου"
                        >
                            <Paperclip className="h-4 w-4" />
                        </Button>
                    </div>
                </div>

                {/* Text Input */}
                <div className="space-y-1">
                    <textarea
                        value={content}
                        onChange={handleContentChange}
                        className={`w-full min-h-[100px] p-2 rounded-md ${internalStyles.textarea} 
                            focus:ring-2 focus:ring-primary-500 focus:border-transparent
                            ${showError ? 'border-red-500 focus:ring-red-500' : ''}`}
                        placeholder={placeholder}
                    />
                    {showError && (
                        <p className="text-sm text-red-600">
                            Παρακαλώ συμπληρώστε το μήνυμά σας
                        </p>
                    )}
                </div>

                {/* File Upload Section */}
                {showFileUpload && (
                    <div className="mt-4">
                        <FileUpload
                            onFilesChange={onFilesChange}
                            existingFiles={files}
                            maxSize={20}
                            compact
                        />
                    </div>
                )}

                {!showFileUpload && files.length > 0 && (
                    <AttachmentPreview
                        files={files}
                        onRemove={handleRemoveFile}
                        compact
                    />
                )}

                <div className="flex justify-end mt-4">
                    <Button
                        size={ButtonSize.SM}
                        onClick={handleSend}
                        className={isInternal ? "bg-amber-600 hover:bg-amber-700" : ""}
                    >
                        {sendButtonText}
                    </Button>
                </div>
            </div>
        </div>
    );
};

const TicketDetails = () => {
    const { id } = useParams<{ id: string }>();
    const [isInternalReply, setIsInternalReply] = useState(false);
    const [activeTab, setActiveTab] = useState<'messages' | 'notes'>('messages');
    const [messageContent, setMessageContent] = useState('');
    const [noteContent, setNoteContent] = useState('');
    const [files, setFiles] = useState<File[]>([]);
    const [showMessageFileUpload, setShowMessageFileUpload] = useState(false);
    const [showNoteFileUpload, setShowNoteFileUpload] = useState(false);


    const userStore = useUserStore(state => state.user);
    const isClientUser = isClient(userStore.roles);

    const ticketId = id ?? '';

    // Queries
    const { data: ticket, isLoading: isTicketLoading } = useGetTicketQuery(ticketId);
    const { data: messages } = useGetTicketMessagesQuery(ticketId);

    const { data: notes } = useQuery({
        queryKey: ['notes', 'list', ticketId],
        queryFn: () => notesApi.getAll(ticketId),
        enabled: !isClientUser
    });

    // Mutations
    const createMessage = useCreateMessageMutation(ticketId);
    const createNote = useCreateNoteMutation(ticketId);
    const updateRating = useUpdateTicketRatingMutation(ticketId);


    if (isTicketLoading || !ticket) {
        return (
            <div className="flex items-center justify-center min-h-screen">
                <div className="animate-pulse text-gray-600">Φόρτωση...</div>
            </div>
        );
    }

    const isContactUser = userStore.user_id === ticket.contact?.user_id;
    const canRate = isContactUser && !ticket.rating_1;

    const handleSendMessage = () => {
        if (!messageContent.trim()) return;

        createMessage.mutate({
            message_content: messageContent,
            is_internal: isClientUser ? false : isInternalReply,
            files
        }, {
            onSuccess: () => {
                setMessageContent('');
                setFiles([]); // Clear files
                setShowMessageFileUpload(false); // Close file upload section
            }
        });
    };

    const handleCreateNote = () => {
        if (!noteContent.trim()) return;

        createNote.mutate({
            note_content: noteContent,
            files
        }, {
            onSuccess: () => {
                setNoteContent('');
                setFiles([]); // Clear files
                setShowMessageFileUpload(false); // Close file upload section
            }
        });
    };

    const handleAttachmentClick = async (attachmentId: string) => {
        try {
            let blob;
            // Check if it's a message or note attachment based on the active tab
            if (activeTab === 'messages') {
                blob = await messagesApi.getAttachment(attachmentId);
            } else {
                blob = await notesApi.getAttachment(attachmentId);
            }

            // Create a temporary URL for the blob
            const url = window.URL.createObjectURL(blob);

            // Open in new tab
            window.open(url, '_blank');

            // Clean up the URL after opening
            setTimeout(() => {
                window.URL.revokeObjectURL(url);
            }, 100);
        } catch (error) {
            console.error('Error downloading attachment:', error);
            toast.error('Σφάλμα κατά τη λήψη του αρχείου');
        }
    };

    return (
        <div className="min-h-screen bg-gray-100">
            <PageHeader
                title={`Ticket #${ticket.ticket_number} - ${ticket.title}`}
                description={(
                    <div className="flex items-center space-x-2 mt-1 flex-wrap">
                        <StatusBadge status={mapStatusToEnum(ticket.status.status_name)} />
                        <PriorityBadge priority={mapPriorityToEnum(ticket.priority.priority_name)} />
                    </div>
                )}
                backButton
                actions={!isClientUser && (
                    <div className="flex items-center space-x-2">
                        <EditTicketDialog
                            ticket={ticket}
                            // companyProducts={ticket.company.companyProducts ?? []}
                        />
                        <TicketDialogs
                            ticketId={ticketId}
                            currentStatus={ticket.status.status_id}
                        />
                    </div>
                )}
            />

            <main className="max-w-7xl mx-auto px-4 py-4 sm:px-6 lg:px-8 sm:py-6">
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 sm:gap-6">
                    {/* Left Column */}
                    <div className="lg:col-span-2 space-y-4 sm:space-y-6">
                        {/* Description Card */}
                        <div className="bg-white rounded-lg shadow p-4 sm:p-6">
                            <h2 className="text-lg font-semibold mb-3 sm:mb-4">Περιγραφή</h2>
                            <p className="text-gray-600 break-words">{ticket.description}</p>
                            <div className="mt-4 flex flex-wrap items-center gap-4">
                                <div className="flex items-center text-sm text-gray-500">
                                    <MessageSquare className="w-4 h-4 mr-1"/>
                                    <span>
                                        {messages?.filter(m => !isClientUser || !m.is_internal).length || 0} μηνύματα
                                    </span>
                                </div>
                                {!isClientUser && (
                                    <div className="flex items-center text-sm text-gray-500">
                                        <NotepadText className="w-4 h-4 mr-1"/>
                                        <span>{notes?.length || 0} σημειώσεις</span>
                                    </div>
                                )}
                            </div>
                        </div>

                        {/* Messages and Notes Tabs */}
                        <div className="bg-white rounded-lg shadow">
                            {/* Tab Navigation */}
                            <div className="border-b border-gray-200">
                                <div className="p-4 sm:p-6">
                                    <nav className="flex space-x-4">
                                        <button
                                            onClick={() => setActiveTab('messages')}
                                            className={`${
                                                activeTab === 'messages'
                                                    ? 'border-primary-700 text-primary-700'
                                                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                                            } flex items-center px-3 py-2 text-sm font-medium border-b-2`}
                                        >
                                            <MessageSquare className="w-4 h-4 mr-2"/>
                                            Συνομιλία
                                            <span className="ml-2 bg-gray-100 text-gray-600 px-2 py-0.5 rounded-full text-xs">
                                                {messages?.filter(m => !isClientUser || !m.is_internal).length || 0}
                                            </span>
                                        </button>

                                        {!isClientUser && (
                                            <button
                                                onClick={() => setActiveTab('notes')}
                                                className={`${
                                                    activeTab === 'notes'
                                                        ? 'border-primary-700 text-primary-700'
                                                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                                                } flex items-center px-3 py-2 text-sm font-medium border-b-2`}
                                            >
                                                <NotepadText className="w-4 h-4 mr-2"/>
                                                Εσωτερικές Σημειώσεις
                                                <span className="ml-2 bg-gray-100 text-gray-600 px-2 py-0.5 rounded-full text-xs">
                                                    {notes?.length || 0}
                                                </span>
                                            </button>
                                        )}
                                    </nav>
                                </div>
                            </div>

                            {/* Messages Tab Content */}
                            {activeTab === 'messages' && (
                                <>
                                    <div className="p-4 sm:p-6 space-y-6">
                                        {messages
                                            ?.filter(message => !isClientUser || !message.is_internal)
                                            .map((message) => (
                                                <Message
                                                    key={message.message_id}
                                                    message={message}
                                                    onAttachmentClick={handleAttachmentClick}
                                                    isInternal={message.is_internal}
                                                />
                                            ))}
                                    </div>

                                    {/* Reply Box */}
                                    <MessageComposer
                                        title="Προσθήκη Μηνύματος"
                                        content={messageContent}
                                        onContentChange={setMessageContent}
                                        onSend={handleSendMessage}
                                        files={files}
                                        onFilesChange={setFiles}
                                        isInternal={isInternalReply}
                                        onInternalChange={setIsInternalReply}
                                        showInternalToggle={!isClientUser}
                                        placeholder={isInternalReply
                                            ? "Το εσωτερικό μήνυμα ΔΕΝ είναι ορατό από τον πελάτη..."
                                            : "Πληκτρολογήστε την απάντησή σας..."
                                        }
                                        showFileUpload={showMessageFileUpload}
                                        onShowFileUploadChange={setShowMessageFileUpload}
                                    />
                                </>
                            )}

                            {/* Notes Tab Content */}
                            {activeTab === 'notes' && !isClientUser && (
                                <>
                                    <div className="p-4 sm:p-6 space-y-6">
                                        {notes?.map((note) => (
                                            <Note
                                                key={note.note_id}
                                                note={note}
                                                onAttachmentClick={(id) => handleAttachmentClick(id)}
                                            />
                                        ))}
                                    </div>

                                    {/* Add Note Box */}
                                    <MessageComposer
                                        title="Προσθήκη Σημείωσης (ΔΕΝ είναι ορατή στον πελάτη)"
                                        content={noteContent}
                                        onContentChange={setNoteContent}
                                        onSend={handleCreateNote}
                                        files={files}
                                        onFilesChange={setFiles}
                                        placeholder="Προσθέστε μια νέα σημείωση..."
                                        sendButtonText="Προσθήκη Σημείωσης"
                                        showFileUpload={showNoteFileUpload}
                                        onShowFileUploadChange={setShowNoteFileUpload}
                                    />
                                </>
                            )}
                        </div>
                    </div>

                    {/* Right Column */}
                    <div className="space-y-4 sm:space-y-6">
                        {/* Ticket Details */}
                        <div className="bg-white rounded-lg shadow p-4 sm:p-6">
                            <h2 className="text-lg font-semibold mb-4">Στοιχεία Ticket</h2>
                            <div className="space-y-4">
                                <div className="flex justify-between items-center">
                                    <div className="flex items-center text-gray-500">
                                        <Building2 className="w-4 h-4 mr-2"/>
                                        <span>Εταιρεία</span>
                                    </div>
                                    <span className="font-medium">{ticket.company.company_name}</span>
                                </div>

                                <div className="flex justify-between items-center">
                                    <div className="flex items-center text-gray-500">
                                        <Briefcase className="w-4 h-4 mr-2"/>
                                        <span>Τμήμα</span>
                                    </div>
                                    <span className="font-medium">{ticket.department.department_name}</span>
                                </div>

                                <div className="flex justify-between items-center">
                                    <div className="flex items-center text-gray-500">
                                        <Tag className="w-4 h-4 mr-2"/>
                                        <span>Κατηγορία</span>
                                    </div>
                                    <span className="font-medium">{ticket.category.category_name}</span>
                                </div>

                                <div className="flex items-center justify-between">
                                    <div className="flex items-center text-gray-500">
                                        <PenLine className="w-4 h-4 mr-2"/>
                                        <span>Δημιουργία</span>
                                    </div>
                                    <div className="flex items-center space-x-2">
                                        <Avatar
                                            firstName={ticket.creator.first_name}
                                            lastName={ticket.creator.last_name}
                                            roleName={ticket.creator.role.role_name}
                                            size="sm"
                                        />
                                        <span className="font-medium">
                                            {`${ticket.creator.first_name} ${ticket.creator.last_name}`}
                                        </span>
                                    </div>
                                </div>

                                <div className="flex items-center justify-between">
                                    <div className="flex items-center text-gray-500">
                                        <UserCog className="w-4 h-4 mr-2"/>
                                        <span>Ανάθεση</span>
                                    </div>
                                    <div className="flex items-center space-x-2">
                                        {ticket.assignee && (
                                            <>
                                                <Avatar
                                                    firstName={ticket.assignee.first_name}
                                                    lastName={ticket.assignee.last_name}
                                                    roleName={ticket.assignee.role.role_name}
                                                    size="sm"
                                                />
                                                <span className="font-medium">
                                                    {`${ticket.assignee.first_name} ${ticket.assignee.last_name}`}
                                                </span>
                                            </>
                                        )}
                                    </div>
                                </div>

                                <div className="flex items-center justify-between">
                                    <div className="flex items-center text-gray-500">
                                        <MessageCircle className="w-4 h-4 mr-2"/>
                                        <span>Αναφορά</span>
                                    </div>
                                    <div className="flex items-center space-x-2">
                                        {ticket.contact && (
                                            <>
                                                <Avatar
                                                    firstName={ticket.contact.first_name}
                                                    lastName={ticket.contact.last_name}
                                                    roleName={ticket.contact.role.role_name}
                                                    size="sm"
                                                />
                                                <span className="font-medium">
                                                    {`${ticket.contact.first_name} ${ticket.contact.last_name}`}
                                                </span>
                                            </>
                                        )}
                                    </div>
                                </div>

                                <div className="flex justify-between items-center">
                                    <div className="flex items-center text-gray-500">
                                        <Calendar className="w-4 h-4 mr-2"/>
                                        <span>Δημιουργήθηκε</span>
                                    </div>
                                    <TimeFormatted date={ticket.created_at} className="font-medium"/>
                                </div>

                                <div className="flex items-center justify-between">
                                    <div className="flex items-center text-gray-500">
                                        <Clock className="w-4 h-4 mr-2"/>
                                        <span>Ενημερώθηκε</span>
                                    </div>
                                    <TimeFormatted date={ticket.updated_at} className="font-medium"/>
                                </div>
                            </div>
                        </div>

                        {/* Rating */}
                        {ticket.status.status_name === StatusEnum.CLOSED && <RatingCard
                            canRate={canRate}
                            ratings={{
                                rating_1: ticket.rating_1,
                                rating_2: ticket.rating_2,
                                rating_3: ticket.rating_3
                            }}
                            onRate={(ratings) => {
                                updateRating.mutate(ratings);
                            }}
                        />}

                        {/* Products Card */}
                        <TicketProductsCard ticket={ticket}/>

                        {/* SLA Status */}
                        <SLAStatusDisplay ticket={ticket}/>

                        {/* Activity Timeline */}
                        <div className="bg-white rounded-lg shadow p-4 sm:p-6">
                            <h2 className="text-lg font-semibold mb-4">Χρονολόγιο Ενεργειών</h2>
                            <TicketTimeline ticketId={ticketId}/>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default TicketDetails;
