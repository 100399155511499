import React from 'react';
import {Clock, FileText, MessageSquare, Plus, Timer,} from 'lucide-react';
import {Card, CardContent} from "@/components/ui/Card";
import Button from '@/components/ui/Button';
import {ButtonSize, ButtonVariant} from '@/components/ui/Button/ButtonSize';
import {useActiveTickets, useCriticalTickets} from "@/hooks/specializedTickets.hooks";
import {TicketListCard} from "@/components/ui/TicketListCard/TicketListCard";
import {useGetStatisticsQuery} from "@/components/features/statistics/services/statistics.queries";
import {useNavigate} from "react-router-dom";

export default function ClientManagerDashboard() {
    const navigate = useNavigate();
    const criticalTickets = useCriticalTickets();
    const activeTickets = useActiveTickets();
    const {data: statistics = []} = useGetStatisticsQuery();

    const getStatisticValueByKey = (key: string) => {
        const item = statistics.find((entry) => entry.key === key);
        return item ? item.value : "N/A";
    };

    return (
        <div className="h-full w-full bg-gray-100">
            <div className="mx-auto p-4 sm:p-6 lg:p-8">
                {/* Header Section */}
                <div className="mb-8">
                    <div className="flex justify-between items-center">
                        <div>
                            <h1 className="text-2xl font-bold text-gray-900">Επισκόπηση Τμήματος</h1>
                            <p className="mt-1 text-sm text-gray-500">
                                Διαχείριση και παρακολούθηση αιτημάτων τμήματος
                            </p>
                        </div>
                        <div className="flex items-center space-x-4">
                            <Button
                                variant={ButtonVariant.PRIMARY}
                                size={ButtonSize.SM}
                                onClick={() => navigate('/tickets/new')}
                            >
                                <Plus className="h-4 w-4 mr-2" />
                                Νέο Αίτημα
                            </Button>
                        </div>
                    </div>
                </div>

                {/* Stats Grid */}
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4 mb-8">
                    {/* Total Department Tickets */}
                    <Card className="bg-blue-50 border-blue-200">
                        <CardContent className="p-6">
                            <div className="flex items-center justify-between">
                                <div>
                                    <p className="text-sm font-medium text-blue-700">Συνολικά Αιτήματα</p>
                                    <div className="flex items-baseline">
                                        <p className="text-2xl font-semibold text-blue-900">
                                            {getStatisticValueByKey("total_tickets")}
                                        </p>
                                    </div>
                                </div>
                                <div className="p-3 bg-blue-100 rounded-lg">
                                    <FileText className="h-6 w-6 text-blue-700"/>
                                </div>
                            </div>
                        </CardContent>
                    </Card>

                    {/* Active Tickets */}
                    <Card className="bg-green-50 border-green-200">
                        <CardContent className="p-6">
                            <div className="flex items-center justify-between">
                                <div>
                                    <p className="text-sm font-medium text-green-700">Ενεργά Αιτήματα</p>
                                    <div className="flex items-baseline">
                                        <p className="text-2xl font-semibold text-green-900">
                                            {getStatisticValueByKey("active_tickets")}
                                        </p>
                                    </div>
                                </div>
                                <div className="p-3 bg-green-100 rounded-lg">
                                    <MessageSquare className="h-6 w-6 text-green-700"/>
                                </div>
                            </div>
                        </CardContent>
                    </Card>

                    {/* Response Time */}
                    <Card className="bg-purple-50 border-purple-200">
                        <CardContent className="p-6">
                            <div className="flex items-center justify-between">
                                <div>
                                    <p className="text-sm font-medium text-purple-700">Μέσος Χρόνος Απόκρισης</p>
                                    <div className="flex items-baseline">
                                        <p className="text-2xl font-semibold text-purple-900">
                                            {getStatisticValueByKey("average_response_time")}
                                        </p>
                                    </div>
                                </div>
                                <div className="p-3 bg-purple-100 rounded-lg">
                                    <Timer className="h-6 w-6 text-purple-700"/>
                                </div>
                            </div>
                        </CardContent>
                    </Card>

                    {/* Resolution Time */}
                    <Card className="bg-yellow-50 border-yellow-200">
                        <CardContent className="p-6">
                            <div className="flex items-center justify-between">
                                <div>
                                    <p className="text-sm font-medium text-yellow-700">Μέσος Χρόνος Επίλυσης</p>
                                    <div className="flex items-baseline">
                                        <p className="text-2xl font-semibold text-yellow-900">
                                            {getStatisticValueByKey("average_resolution_time")}
                                        </p>
                                    </div>
                                </div>
                                <div className="p-3 bg-yellow-100 rounded-lg">
                                    <Clock className="h-6 w-6 text-yellow-700"/>
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                </div>

                {/* Main Content Grid */}
                <div className="grid grid-cols-1 gap-8 lg:grid-cols-2">
                    <TicketListCard
                        title="Ενεργά Αιτήματα"
                        tickets={activeTickets.data?.data ?? []}
                        badgeCount={activeTickets.data?.total_results ?? 0}
                        badgeText="συνολικά"
                        badgeVariant="outline"
                        actionButtonText="Προβολή"
                        onTicketAction={activeTickets.handleTicketClick}
                        onViewAll={activeTickets.handleViewAll}
                    />

                    <TicketListCard
                        title="Κρίσιμα Αιτήματα"
                        tickets={criticalTickets.data?.data ?? []}
                        badgeCount={criticalTickets.data?.total_results ?? 0}
                        badgeText="συνολικά"
                        badgeVariant="error"
                        showSLA={true}
                        actionButtonText="Προβολή"
                        onTicketAction={criticalTickets.handleTicketClick}
                        onViewAll={criticalTickets.handleViewAll}
                    />

                </div>
            </div>
        </div>
    );
}

