// src/features/tickets/services/tickets.queries.ts
import { useQuery } from '@tanstack/react-query';
import { ticketsApi } from './tickets.api';
import { TICKET_KEYS } from './tickets.queryKeys';
import type { TicketFilters } from '@/types/ticket.types';
import {StatusEnum} from "@/types/status.types";
import {useGetStatusesQuery} from "@/components/features/statuses/services/statuses.queries";

export function useGetTicketsQuery(filters?: TicketFilters) {
    return useQuery({
        queryKey: TICKET_KEYS.list(filters),
        queryFn: () => ticketsApi.getAll(filters),
        staleTime: 1000 * 60 * 5, // 5 minutes
        refetchOnWindowFocus: true
    });
}

export function useGetTicketQuery(id: string) {
    return useQuery({
        queryKey: TICKET_KEYS.detail(id),
        queryFn: () => ticketsApi.getById(id),
        enabled: Boolean(id),
        staleTime: 1000 * 60 * 5 // 5 minutes
    });
}

export function useGetTicketAuditsQuery(ticketId: string) {
    const statusAudits = useQuery({
        queryKey: [...TICKET_KEYS.detail(ticketId), 'status-audits'],
        queryFn: () => ticketsApi.getStatusAudits(ticketId),
        enabled: Boolean(ticketId),
    });

    const assignmentAudits = useQuery({
        queryKey: [...TICKET_KEYS.detail(ticketId), 'assignment-audits'],
        queryFn: () => ticketsApi.getAssignmentAudits(ticketId),
        enabled: Boolean(ticketId),
    });

    return {
        statusAudits,
        assignmentAudits,
        isLoading: statusAudits.isLoading || assignmentAudits.isLoading,
        isError: statusAudits.isError || assignmentAudits.isError,
    };
}

export function useGetUnassignedTicketsQuery(pageSize: number = 5) {
    // First, get all statuses
    const { data: statuses, isLoading: isLoadingStatuses } = useGetStatusesQuery();

    // Find the NEW status ID
    const newStatusId = statuses?.find(
        status => status.status_name === StatusEnum.NEW
    )?.status_id;

    // Then get tickets with the correct status ID
    const ticketsQuery = useQuery({
        queryKey: TICKET_KEYS.list({
            page_number: 1,
            page_size: pageSize,
            status_id: newStatusId
        }),
        queryFn: () => ticketsApi.getAll({
            page_number: 1,
            page_size: pageSize,
            status_id: newStatusId
        }),
        // Only run this query when we have the status ID
        enabled: Boolean(newStatusId),
        staleTime: 1000 * 60 * 5, // 5 minutes
        refetchOnWindowFocus: true
    });

    return {
        ...ticketsQuery,
        isLoading: isLoadingStatuses || ticketsQuery.isLoading
    };
}

export function useGetCriticalTicketsQuery(pageSize: number = 5) {
    return useQuery({
        queryKey: TICKET_KEYS.list({
            page_number: 1,
            page_size: pageSize,
            active: true,
            critical: true
        }),
        queryFn: () => ticketsApi.getAll({
            page_number: 1,
            page_size: pageSize,
            active: true,
            critical: true
        }),
        staleTime: 1000 * 60 * 5, // 5 minutes
        refetchOnWindowFocus: true
    });
}

export function useGetActiveTicketsQuery(pageSize: number = 5) {
    return useQuery({
        queryKey: TICKET_KEYS.list({
            page_number: 1,
            page_size: pageSize,
            active: true
        }),
        queryFn: () => ticketsApi.getAll({
            page_number: 1,
            page_size: pageSize,
            active: true
        }),
        staleTime: 1000 * 60 * 5,
        refetchOnWindowFocus: true
    });
}

export function useGetAboutToBreachTicketsQuery(pageSize: number = 5) {
    return useQuery({
        queryKey: TICKET_KEYS.list({
            page_number: 1,
            page_size: pageSize,
            active: true,
            about_to_breach: true
        }),
        queryFn: () => ticketsApi.getAll({
            page_number: 1,
            page_size: pageSize,
            active: true,
            about_to_breach: true
        }),
        staleTime: 1000 * 60 * 5,
        refetchOnWindowFocus: true
    });
}
