import React from 'react'

type CheckboxSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl'

interface CheckboxProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'> {
    label?: string
    description?: string
    size?: CheckboxSize
    error?: string
}

const checkboxSizeClasses = {
    xs: 'h-3 w-3',
    sm: 'h-4 w-4',
    md: 'h-5 w-5',
    lg: 'h-6 w-6',
    xl: 'h-7 w-7',
}

const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
    ({ label, description, size = 'md', className = '', error, id, ...props }, ref) => {
        // Generate unique ID if none provided
        const checkboxId = id || `checkbox-${Math.random().toString(36).substr(2, 9)}`
        const descriptionId = description ? `${checkboxId}-description` : undefined

        const sizeClasses = checkboxSizeClasses[size]
        const combinedClassName = `
            ${sizeClasses}
            rounded border-gray-300 text-primary-600
            focus:ring-primary-500 
            disabled:cursor-not-allowed disabled:opacity-50
            ${error ? 'border-error-500' : ''}
            ${className}
        `

        return (
            <div className="relative flex items-start p-0">
                <div className="min-w-0 flex-1 text-sm">
                    {label && (
                        <label
                            htmlFor={checkboxId}
                            className="font-medium text-gray-700 select-none"
                        >
                            {label}
                        </label>
                    )}
                    {description && (
                        <p 
                            id={descriptionId}
                            className="text-gray-500 mt-1"
                        >
                            {description}
                        </p>
                    )}
                    {error && (
                        <p className="mt-1 text-sm text-error-500">
                            {error}
                        </p>
                    )}
                </div>
                <div className="ml-3 flex h-5 items-center">
                    <input
                        ref={ref}
                        type="checkbox"
                        id={checkboxId}
                        className={combinedClassName}
                        aria-describedby={descriptionId}
                        {...props}
                    />
                </div>
            </div>
        )
    }
)

Checkbox.displayName = 'Checkbox'

export default Checkbox
