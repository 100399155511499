// cases.queries.ts
import {casesApi} from "@/components/features/cases/services/cases.api";
import {CaseFilters} from "@/types/case.types";
import {useQuery} from "@tanstack/react-query";
import {CASE_KEYS} from "@/components/features/cases/services/cases.queryKeys";

export function useGetCasesQuery(filters?: CaseFilters) {
    return useQuery({
        queryKey: CASE_KEYS.list(filters),
        queryFn: () => casesApi.getAll(filters),
        staleTime: 1000 * 60 * 5, // 5 minutes
        refetchOnWindowFocus: true,
    });
}

export function useGetCaseQuery(id: string) {
    return useQuery({
        queryKey: CASE_KEYS.detail(id),
        queryFn: () => casesApi.getById(id),
        enabled: Boolean(id),
        staleTime: 1000 * 60 * 5 // 5 minutes
    });
}
