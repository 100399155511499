// CaseEditModal.tsx
import React from 'react';
import Modal from '@/components/ui/Modal';
import InputWithLabel from '@/components/ui/InputWithLabel';
import TextArea from '@/components/ui/TextArea';
import CategoryDropdown from '@/components/ui/DropdownWithLabel/CategoryDropdown';
import PriorityDropdown from '@/components/ui/DropdownWithLabel/PriorityDropdown';
import { useUpdateCaseMutation } from '../services/cases.mutations';
import { FileEdit } from 'lucide-react';
import type { Case } from '@/types/case.types';

interface CaseEditModalProps {
    caseData: Case;
    open: boolean;
    setOpen: (open: boolean) => void;
}

export const CaseEditModal: React.FC<CaseEditModalProps> = ({
                                                                caseData,
                                                                open,
                                                                setOpen
                                                            }) => {
    const [formData, setFormData] = React.useState({
        title: caseData.title,
        description: caseData.description,
        category_id: caseData.category.category_id,
        priority_id: caseData.priority.priority_id,
        status_id: caseData.status.status_id,
        assignee_id: caseData.assignee?.user_id,
        tickets: caseData.tickets.map(t => t.ticket.ticket_id)
    });

    const updateCase = useUpdateCaseMutation(caseData.case_id);

    const handleSubmit = async () => {
        await updateCase.mutateAsync(formData);
        setOpen(false);
    };

    return (
        <Modal
            open={open}
            setOpen={setOpen}
            title="Επεξεργασία Υπόθεσης"
            icon={<FileEdit className="h-6 w-6 text-primary-600" />}
            primaryAction={{
                text: updateCase.isPending ? 'Αποθήκευση...' : 'Αποθήκευση',
                onClick: handleSubmit
            }}
            secondaryAction={{
                text: 'Ακύρωση',
                onClick: () => setOpen(false)
            }}
            className="sm:max-w-xl"
        >
            <div className="mt-4 space-y-4">
                <InputWithLabel
                    label="Τίτλος"
                    value={formData.title}
                    onChange={(e) => setFormData(prev => ({
                        ...prev,
                        title: e.target.value
                    }))}
                    required
                />
                <TextArea
                    label="Περιγραφή"
                    value={formData.description}
                    onChange={(e) => setFormData(prev => ({
                        ...prev,
                        description: e.target.value
                    }))}
                    required
                />
                <div className="grid grid-cols-2 gap-4">
                    <CategoryDropdown
                        value={formData.category_id}
                        onChange={(value) => setFormData(prev => ({
                            ...prev,
                            category_id: value
                        }))}
                        required
                    />
                    <PriorityDropdown
                        value={formData.priority_id}
                        onChange={(value) => setFormData(prev => ({
                            ...prev,
                            priority_id: value
                        }))}
                        required
                    />
                </div>
            </div>
        </Modal>
    );
};
